import { Typography } from '@mui/material';
import React, { useState } from 'react';

function Readmore({ detailText }) {
  const [readMore, setReadMore] = useState(true);

  const toggleReadMore = () => setReadMore(!readMore);
  let readMoreWord = detailText?.split(' ');

  return (
    <>
      <Typography>
        {readMore ? readMoreWord?.slice(0, 30).join(' ') : detailText}
        <Typography
          variant="body"
          sx={{
            display: readMoreWord?.length < 30 ? 'none' : 'inline',
            cursor: 'pointer',
          }}
          onClick={toggleReadMore}
        >
          {readMore ? ' Read More' : ' Read Less'}
        </Typography>
      </Typography>
    </>
  );
}

export default Readmore;
