import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';

function ResetPasswordFailed() {
  const navigate = useNavigate();
  return (
    <>
      <Box component="img" src="../assets/error.svg" height={200} />
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography fontWeight="500" align="center" mt={1}>
          Reset password failed
        </Typography>
        <Typography align="center" mt={1}>
          Your token is not valid. Please request reset password again on login
          page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          startIcon={<HomeIcon />}
          sx={{ py: 2, px: 3, textTransform: 'none', mt: 5 }}
          onClick={() => navigate('/login')}
        >
          <Typography variant="subtitle1" fontWeight="600">
            Login Here
          </Typography>
        </Button>
      </Box>
    </>
  );
}

export default ResetPasswordFailed;
