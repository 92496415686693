import { Box, Button, Grid, Skeleton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatDate, formatDateNoDay, formatNoInvoice } from '../utils';

const primary = '#5B4947'
const evenCell = 'rgba(91, 73, 71, 0.2);'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: primary,
    color: theme.palette.common.white,
    borderLeft: 0,
    borderRight: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 2
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: evenCell,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const handlePrice = (course) => {
  let total = 0
  course.map((e) => {
    return total += Number(e.product_price)
  })

  return formatCurrency(total)
}

const DataTableDashboardInvoice = (props) => {
  const { loading, columns, data, title, invoiceDetail, invoice, noInvoice, date, totalPrice, name } = props

  const theme = useTheme();
  theme.typography.h5 = {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  };

  theme.typography.h6 = {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  };

  theme.typography.subtitle1 = {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Box mt={2}>
        <Typography
          variant='h6'
          lineHeight={3}
          fontWeight={600}>{title}</Typography>

        {!invoice && <InfoDetail
          noInvoice={noInvoice}
          date={date}
          totalPrice={totalPrice}
          name={name}
        />}

        <Box maxWidth='lg'>
          <TableContainer >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns.map((column) =>
                    <StyledTableCell align="center" key={column}>{column}</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              {/* {console.log(data)} */}
              {!invoiceDetail && <BodyInvoice data={data} />}
              {!invoice && <BodyInvoiceDetail data={data} />}

              {!loading && data.length === 0 &&
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={7} align='center'> No data or record available </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              }

              {loading &&
                <TableBody>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <TableRow key={item}>
                      <StyledTableCell colSpan={7} align='center'>
                        <Skeleton variant="rectangular" sx={{ height: 50 }} />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              }


            </Table>
          </TableContainer>

        </Box>
      </Box >
    </ThemeProvider>


  )
}


function BodyInvoice(props) {
  const navigate = useNavigate()
  const { data } = props

  return (
    <TableBody>
      {data.map((row, i) => (

        <StyledTableRow key={row.guid}>
          <StyledTableCell width="5%" align="center">{i + 1}</StyledTableCell>
          <StyledTableCell width="5%" align="center">{row.name}</StyledTableCell>
          <StyledTableCell width="19%" align="center">{formatNoInvoice(row.no_invoice)}</StyledTableCell>
          <StyledTableCell width="19%" align="center">{formatDateNoDay(row.order_date)}</StyledTableCell>
          <StyledTableCell width="19%" align="center">{row.cart_product.length}</StyledTableCell>
          <StyledTableCell width="19%" align="center">{handlePrice(row.cart_product)}</StyledTableCell>
          <StyledTableCell width="19%" align="center">
            <Button
              variant="contained"
              disableElevation
              color='primary'
              sx={{ px: 5, textTransform: 'none' }}
              onClick={() => navigate(`/dashboard/invoice/${row.guid}`, { state: { course: row.cart_product, noInvoice: row.no_invoice, date: formatDateNoDay(row.order_date), totalPrice: handlePrice(row.cart_product), name: row.name } })}>
              <Typography fontWeight={700}>Details
              </Typography>
            </Button>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableBody>
  );
}

function BodyInvoiceDetail(props) {
  const { data } = props

  return (
    <TableBody>
      {data.map((row, i) => (

        <StyledTableRow key={row.guid}>
          <StyledTableCell width="5%" align="center">{i + 1}</StyledTableCell>
          <StyledTableCell width="50%" align="center">{row.product_name}</StyledTableCell>
          <StyledTableCell width="15%" align="center">{row.category_name}</StyledTableCell>
          <StyledTableCell width="15%" align="center">{formatDate(row.schedule)}</StyledTableCell>
          <StyledTableCell width="15%" align="center">{formatCurrency(row.product_price)}</StyledTableCell>
        </StyledTableRow>
      ))}


    </TableBody>
  );
}

function InfoDetail(props) {
  const { noInvoice, date, totalPrice, name } = props


  return (
    <Box maxWidth='lg' mt={2} mb={3} display='flex'>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} >
          <Box display='flex'>
            <Box display='flex' sx={{ maxWidth: '200px' }} mr={3} flexDirection='column' gap={0.5}>
              <Typography
                variant='subtitle1'
                lineHeight={2}
                fontWeight={500}>Customer Name : </Typography>
              <Typography
                variant='subtitle1'
                lineHeight={2}
                fontWeight={500}>No Invoice : </Typography>
              <Typography
                variant='subtitle1'
                lineHeight={2}
                fontWeight={500}>Date : </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={0.5}>
              <Typography
                flex={3}
                variant='subtitle1'
                lineHeight={2}
                fontWeight={500}>{name}</Typography>
              <Typography
                flex={3}
                variant='subtitle1'
                lineHeight={2}
                fontWeight={500}>{formatNoInvoice(noInvoice)}</Typography>
              <Typography
                flex={3}
                variant='subtitle1'
                lineHeight={2}
                fontWeight={500}>{date}</Typography>
            </Box>
          </Box>

        </Grid>
        <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='flex-end' alignSelf='flex-end'>

          <Box display='flex' flexDirection='row' gap={2}>
            <Typography
              variant='subtitle1'
              lineHeight={3}
              fontWeight={700}>Total Price</Typography>
            <Typography
              variant='subtitle1'
              lineHeight={3}
              fontWeight={700}>{totalPrice}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DataTableDashboardInvoice
