import {
  Alert,
  Button,
  Paper,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../utils/index';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import DialogDashboardAddUser from '../../components/DialogDashboardAddUser';
import DialogDashboardEditUser from '../../components/DialogDashboardEditUser';
import axiosApi from '../../utils/axiosApi';

function DashboardUser() {
  const defaultUser = {
    guid: '',
    name: '',
    email: '',
    role_name: '',
    role_id: 1,
    verified: false,
    active: false,
    register_date: '',
  };
  const [editUserDialog, setEditUserDialog] = useState(false);
  const [newUserDialog, setNewUserDialog] = useState(false);
  const [allUserData, setAllUserData] = useState([]);
  const [allRole, setAllRole] = useState([{ id: 0, role_name: '' }]);
  const [editedUser, setEditedUser] = useState(defaultUser);
  const [isLoading, setIsLoading] = useState(true);

  const [snackbar, setSnackbar] = useState({
    open: false,
    status: 'success',
    message: '',
  });

  const refreshDataUser = () => {
    axiosApi
      .get('api/user/all')
      .then((res) => res.data)
      .then((res) =>
        res.map((each) => {
          return {
            ...each,
            verified: each.verified,
            active: each.active,
          };
        })
      )
      .then((res) => setAllUserData(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    document.title = 'Soup Project | Dashboard User';
    refreshDataUser();
    axiosApi
      .get('api/role')
      .then((res) => {
        setAllRole(res.data);
        _.delay(() => setIsLoading(false), 1000);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (snackbar.open === true) {
      refreshDataUser();
    }
  }, [snackbar]);

  const onClickEdit = (guid) => {
    const dataUser = _.find(allUserData, { guid: guid });
    const dataRole = _.find(allRole, { role_name: dataUser.role_name });
    setEditedUser({ ...dataUser, role_id: dataRole.id });
  };

  const handleOpenEditUserDialog = () => {
    setEditUserDialog(true);
  };

  const handleOpenCloseSnackbar = (type) => {
    type === 'open' && setSnackbar((prev) => ({ ...prev, open: true }));
    type === 'close' && setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Paper>
        <Box px={3} py={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" ml={1}>
              All User
            </Typography>
            {isLoading && (
              <Skeleton variant="rectangular" height={35} width={150} />
            )}
            {!isLoading && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                sx={{ mx: 2, width: '150px' }}
                onClick={() => setNewUserDialog(true)}
              >
                Add User
              </Button>
            )}
          </Box>
          {isLoading && (
            <Box>
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
            </Box>
          )}
          {!isLoading && (
            <Box component={TableContainer} mt={2}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Verified</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Register Date</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUserData.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {user.name}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{_.startCase(user.role_name)}</TableCell>
                      <TableCell>
                        {user.verified ? <CheckIcon /> : <CloseIcon />}
                      </TableCell>
                      <TableCell>
                        {user.active ? <CheckIcon /> : <CloseIcon />}
                      </TableCell>
                      <TableCell>{formatDate(user.register_date)}</TableCell>
                      <TableCell>
                        <Link
                          onClick={() => {
                            handleOpenEditUserDialog();
                            onClickEdit(user.guid);
                          }}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Paper>

      <DialogDashboardEditUser
        editUserDialog={editUserDialog}
        editedUser={editedUser}
        allRole={allRole}
        setEditUserDialog={setEditUserDialog}
        setEditedUser={setEditedUser}
        defaultUser={defaultUser}
        setSnackbar={setSnackbar}
      />
      <DialogDashboardAddUser
        openDialog={newUserDialog}
        setOpenDialog={setNewUserDialog}
        allRole={allRole}
        setSnackbar={setSnackbar}
        refresh={refreshDataUser}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => handleOpenCloseSnackbar('close')}
          severity={snackbar.status}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DashboardUser;
