import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainLayout } from '../components';
import MainLayoutDashboard from '../components/reusable/MainLayoutDashboard';
import Category from '../pages/Category';
import CategoryDetail from '../pages/CategoryDetail';
import Checkout from '../pages/Checkout';
import Course from '../pages/Course';
import CourseDetail from '../pages/CourseDetail';
import DashboardCategory from '../pages/dashboard/DashboardCategory';
import Dashboard from '../pages/dashboard/Dashboard';
import EmailConfirmation from '../pages/EmailConfirmation';
import Index from '../pages/Index';
import Invoice from '../pages/Invoice';
import InvoiceDetail from '../pages/InvoiceDetail';
import Login from '../pages/Login';
import MyClass from '../pages/MyClass';
import NotFound from '../pages/NotFound';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import ResetPasswordOtp from '../pages/ResetPasswordOtp';
import SuccessOrder from '../pages/SuccessOrder';
import DashboardCourse from '../pages/dashboard/DashboardCourse';
import DashboardInvoice from '../pages/dashboard/DashboardInvoice';
import DashboardInvoiceDetail from '../pages/dashboard/DashboardInvoiceDetail';
import DashboardPaymentMethod from '../pages/dashboard/DashboardPaymentMethod';
import DashboardUser from '../pages/dashboard/DashboardUser';
import DashboardProfile from '../pages/dashboard/DashboardProfile';
import CheckoutBuyNow from '../pages/CheckoutBuynow';

function MainRouting() {
  return (
    <Routes>
      {/* erik */}
      <Route element={<MainLayout />}>
        <Route index path="/" element={<Index />} />
        <Route path="/category/">
          <Route path="*" element={<CategoryDetail />} />
          <Route index element={<Category />} /> {/* Opsional */}
          <Route path=":name" element={<CategoryDetail />} />
        </Route>
        <Route path="/course/">
          <Route index element={<Course />} /> {/* Opsional */}
          <Route path=":name" element={<CourseDetail />} />
        </Route>
      </Route>

      {/* hosea */}
      <Route element={<MainLayout noFooter />}>
        <Route index path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route index path="/konfirmasi" element={<EmailConfirmation />} />
        <Route path="/reset-password/">
          <Route index element={<ResetPassword />} />
          <Route path="otp" element={<ResetPasswordOtp />} />
        </Route>
      </Route>

      {/* novi */}
      <Route path="/checkout/" element={<MainLayout noFooter />}>
        <Route index element={<Checkout />} />
        <Route path="success" element={<SuccessOrder />} />
        <Route path="buynow" element={<CheckoutBuyNow />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/invoice/">
          <Route index element={<Invoice />} />
          <Route path=":guid" element={<InvoiceDetail />} />
        </Route>
        <Route path="/myclass" element={<MyClass />} />
      </Route>

      {/* dashboard */}
      <Route path="/dashboard/" element={<MainLayoutDashboard />}>
        <Route index element={<Dashboard />} />
        <Route path="category" element={<DashboardCategory />} />
        <Route path="course" element={<DashboardCourse />} />
        <Route path="invoice" element={<DashboardInvoice />} />
        <Route path="invoice/:guid" element={<DashboardInvoiceDetail />} />
        <Route path="payment-method" element={<DashboardPaymentMethod />} />
        <Route path="users" element={<DashboardUser />} />
        <Route path="profile" element={<DashboardProfile />} />
      </Route>

      {/* not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MainRouting;
