import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';

function BannerLandingPage({
  bannerImage,
  align,
  headText,
  bodyText,
  bannerSize,
}) {
  return (
    <Paper
      sx={{
        position: 'relative',
        mb: '60px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${bannerImage})`,
        width: '100%',
        height: `${bannerSize}`,
      }}
    >
      {<img style={{ display: 'none' }} src={bannerImage} alt="banner" />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100%',
          height: '100%',
          flexDirection: 'column',
          px: 2,
          gap: '27px',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            color="white.main"
            fontWeight={600}
            align={align}
            fontFamily="Montserrat"
          >
            {headText}
          </Typography>
          <Typography
            variant="subtitle1"
            color="white.main"
            align={align}
            fontFamily="Montserrat"
          >
            {bodyText}
          </Typography>
        </Container>
      </Box>
    </Paper>
  );
}

export default BannerLandingPage;
