import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import _ from 'lodash';
import axiosApi from '../utils/axiosApi';

function DialogDashboardEditUser({
  editUserDialog,
  editedUser,
  allRole,
  setEditUserDialog,
  setEditedUser,
  defaultUser,
  setSnackbar,
}) {
  const defaultNewUser = {
    name: null,
    password: null,
    role_id: null,
    verified: null,
    active: null,
  };
  const [errorForm, setErrorForm] = useState({
    name: false,
    nameMessage: '',
    email: false,
    emailMessage: '',
    password: false,
    passwordMessage: '',
  });
  const [newEditedUser, setNewEditedUser] = useState(defaultNewUser);

  const onChangeName = (e) => {
    setNewEditedUser((prev) => ({ ...prev, name: e.target.value }));
    setErrorForm((prev) => ({ ...prev, name: false, nameMessage: '' }));
  };

  const onChangePassword = (e) => {
    setNewEditedUser((prev) => ({
      ...prev,
      password: e.target.value === '' ? null : e.target.value,
    }));
    setErrorForm((prev) => ({ ...prev, password: false, passwordMessage: '' }));
  };

  const onChangeRole = (e) => {
    setNewEditedUser((prev) => ({ ...prev, role_id: e.target.value }));
  };

  const onChangeVerified = () => {
    setNewEditedUser((prev) => ({
      ...prev,
      verified: prev.verified === null ? !editedUser.verified : !prev.verified,
    }));
  };

  const onChangeActive = () => {
    setNewEditedUser((prev) => ({
      ...prev,
      active: prev.active === null ? !editedUser.verified : !prev.active,
    }));
  };

  const handleCloseEditUserDialog = () => {
    setEditUserDialog(false);
    setEditedUser(defaultUser);
    setNewEditedUser(defaultNewUser);
  };

  const handleClickChange = async () => {
    if (newEditedUser.name === '') {
      return setErrorForm((prev) => ({
        ...prev,
        name: true,
        nameMessage: 'Name cannot empty',
      }));
    }
    if (newEditedUser.password !== null && newEditedUser.password.length < 8) {
      return setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'Minimum password is 8 character',
      }));
    }
    const data = {
      name: newEditedUser.name,
      newPassword: newEditedUser.password,
      fk_role_id: newEditedUser.role_id,
      verified: newEditedUser.verified === null ? null : newEditedUser.verified,
      active: newEditedUser.active === null ? null : newEditedUser.active,
    };
    await axiosApi
      .put(`api/user/${editedUser.guid}`, JSON.stringify(data))
      .then((res) => {
        if (
          res.data.name ||
          res.data.verified ||
          res.data.active ||
          res.data.role ||
          res.data.password
        ) {
          setSnackbar({
            open: true,
            status: 'success',
            message: `Success edit user with email ${editedUser.email}`,
          });
        }
        handleCloseEditUserDialog();
      })
      .catch((err) => console.log(err));
  };

  console.log({ newEditedUser });
  console.log({ editedUser });

  return (
    <Dialog open={editUserDialog} onClose={handleCloseEditUserDialog}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          label="Name"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          value={
            newEditedUser.name !== null ? newEditedUser.name : editedUser.name
          }
          onChange={onChangeName}
          error={errorForm.name}
          helperText={errorForm.nameMessage}
        />
        <TextField
          id="email"
          label="Email"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          value={editedUser.email}
          disabled
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          value={newEditedUser.password !== null ? newEditedUser.password : ''}
          onChange={onChangePassword}
          error={errorForm.password}
          helperText={errorForm.passwordMessage}
        />
        <Box mt={2}>
          <FormControl>
            <FormLabel id="role">Role</FormLabel>
            <RadioGroup
              row
              aria-labelledby="role"
              name="role"
              value={
                newEditedUser.role_id != null
                  ? newEditedUser.role_id
                  : editedUser.role_id
              }
              onChange={onChangeRole}
            >
              {allRole.map((each, index) => (
                <FormControlLabel
                  value={each.id}
                  control={<Radio />}
                  label={_.startCase(each.role_name)}
                  key={index}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mt={3}>
          <FormLabel component="legend">Verified</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Switch name="active" />}
              checked={
                newEditedUser.verified !== null
                  ? newEditedUser.verified
                  : editedUser.verified
              }
              onChange={onChangeVerified}
              label={
                newEditedUser.verified !== null
                  ? newEditedUser.verified
                    ? 'Yes'
                    : 'No'
                  : editedUser.verified
                  ? 'Yes'
                  : 'No'
              }
            />
          </FormGroup>
        </Box>
        <Box mt={3}>
          <FormLabel component="legend">Active User</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Switch name="active" />}
              checked={
                newEditedUser.active !== null
                  ? newEditedUser.active
                  : editedUser.active
              }
              onChange={onChangeActive}
              label={
                newEditedUser.active !== null
                  ? newEditedUser.active
                    ? 'Yes'
                    : 'No'
                  : editedUser.active
                  ? 'Yes'
                  : 'No'
              }
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditUserDialog}>Cancel</Button>
        <Button
          onClick={handleClickChange}
          disabled={
            newEditedUser.name === null &&
            newEditedUser.active === null &&
            newEditedUser.verified === null &&
            newEditedUser.role_id === null &&
            newEditedUser.password === null
          }
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDashboardEditUser;
