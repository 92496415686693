import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import CheckListCourse from '../components/CheckListCourse';
import CoursesList from '../components/reusable/CoursesList';
import axiosApi from '../utils/axiosApi';

function AllCourses() {
  const [checked, setChecked] = useState([]);
  const [coursesApi, setCoursesApi] = useState([]);
  const [gettingCourse, setGettingCourse] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [gettingCategory, setGettingCategory] = useState(false);

  useEffect(() => {
    const getDataCagetory = async () => {
      try {
        setGettingCategory(true);
        const res = await axiosApi.get('/api/category');
        const data = res.data;
        setCategoryList(
          data?.map((data) => {
            return {
              id: data.id,
              categoryName: data.category_name,
              slug: data.slug,
            };
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        setGettingCategory(false);
      }
    };

    const getDataCourse = async () => {
      try {
        setGettingCourse(true);
        const res = await axiosApi.get('/api/product');
        const data = res.data;
        setCoursesApi(
          data?.map((data) => {
            return {
              id: data.id,
              productName: data.product_name,
              productPrice: data.product_price,
              productImage: data.product_image,
              category: data.fk_category_id,
              categoryName: data.category_name,
              slug: data.slug,
            };
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        setGettingCourse(false);
      }
    };

    getDataCagetory();
    getDataCourse();
  }, []);

  useEffect(() => {
    document.title = `Soup Project`;
  }, []);

  useEffect(() => {
    if (!categoryList.length === 0) return;
    categoryList.map((data) =>
      setChecked((prev) => [...prev, data.categoryName])
    );
  }, [gettingCourse, categoryList]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    setChecked(newChecked);
  };

  const dataDisplayed = coursesApi.filter((data) =>
    checked.includes(data.categoryName)
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      mt={5}
      mx={1}
      flexGrow={1}
    >
      <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
        <Box mb={4}>
          <Typography variant="h5" fontWeight={600} mb={2}>
            All Category
          </Typography>
          <CheckListCourse
            listOfCourse={categoryList}
            handleToggle={handleToggle}
            checked={checked}
          />
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
        <CoursesList
          dataCourses={dataDisplayed}
          gettingCourse={gettingCourse}
        />
      </Container>
    </Box>
  );
}

export default AllCourses;
