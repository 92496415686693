import axios from 'axios';

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    timeout: 5000,
  },
});

axiosApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth');
  config.headers['Authorization'] = 'Bearer ' + token;
  return config;
});

export default axiosApi;
