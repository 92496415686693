import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import { Link } from 'react-router-dom';

function CategoryList({ dataCategory, gettingCategory }) {
  return (
    <>
      {gettingCategory && (
        <Box
          sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}
          my={{ xs: 2, lg: 10 }}
          mx={{ xs: 5, lg: 30 }}
        >
          {Array.from({ length: 6 }, (_, i) => i + 1).map((data) => (
            <Stack key={data} spacing={1}>
              <Skeleton
                variant="rectangular"
                width={200}
                height={133}
                sx={{ borderRadius: '20px' }}
              />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Stack>
          ))}
        </Box>
      )}
      <Grid container spacing={{ xs: 2, lg: 4 }} px={{ xs: 2, lg: 12 }} mt={10}>
        {dataCategory.map((category, i) => {
          let url = category.slug;
          return (
            <Grid item xs={6} md={4} lg={3} key={i}>
              <Card sx={{ border: 'none', boxShadow: 'none' }}>
                <CardActionArea component={Link} to={`/category/${url}`}>
                  <CardMedia
                    component="img"
                    width="200px"
                    image={category.categoryImage}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      align="center"
                      fontFamily={'Inter'}
                    >
                      {category.categoryName}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default CategoryList;
