import React, { useState, useEffect, createContext } from 'react';
import { isAuthenticated } from './isAuthenticated';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const checkLoggedIn = () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        localStorage.setItem('auth', '');
        cuser = '';
      }

      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
