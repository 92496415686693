import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';

function CheckListCourse({ listOfCourse, handleToggle, checked }) {
  return (
    <List
      dense
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
      }}
    >
      {listOfCourse.map((data, i) => (
        <ListItem
          key={data.categoryName}
          sx={{
            border: '1px solid',
            maxWidth: 'fit-content',
            p: 0.5,
            borderRadius: 3,
            borderColor: 'primary.main',
          }}
        >
          <ListItemButton
            dense
            sx={{ p: 0 }}
            role={undefined}
            onClick={handleToggle(data.categoryName)}
          >
            <ListItemText
              variant="subtile2"
              id={data.categoryName}
              fontFamily={'Montserrat'}
              primaryTypographyProps={{ fontWeight: 500 }}
              primary={data.categoryName}
            />
            <ListItemIcon>
              <Checkbox
                edge="end"
                disableRipple
                checked={checked.indexOf(data.categoryName) !== -1}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default CheckListCourse;
