import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Divider,
  Snackbar,
  Typography,
  Stack,
  Skeleton,
} from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CoursesList from '../components/reusable/CoursesList';

import SelectionCourseDetail from '../components/SelectionCourseDetail';
import Readmore from '../components/Readmore';

import axiosApi from '../utils/axiosApi';

import { formatCurrency } from '../utils';
import TransactionContext from '../utils/TransactionContext';
import UserContext from '../utils/UserContext';

import jwt_decode from 'jwt-decode';
import _ from 'lodash';

function CourseDetail() {
  const [course, setCourse] = useState({});
  const [coursesApi, setCoursesApi] = useState([]);
  const [date, setDate] = useState('');
  const [added, setAdded] = useState(false);
  const [alreadyAdded, setalreadyAdded] = useState(false);
  const [alreadyOrderd, setAlreadyOrderd] = useState(false);
  const [getSingleCourse, setGetSingleCourse] = useState(true);

  const { currentCart, setUpdateOnCart, updateOnCart, currentMyClass } =
    useContext(TransactionContext);

  const { currentUser } = useContext(UserContext);
  const token = currentUser ? jwt_decode(currentUser) : {};

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const filterdataToArray = (array, data) => {
    const returned = array
      ?.filter((cart) => cart.fk_product_id === data.fk_product_id)
      ?.map((cur) => ({ ...cur, schedule: cur.schedule.slice(0, 10) }))
      ?.find((cur) => cur.schedule === data.schedule);
    return returned;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!getSingleCourse) return;
    const getSingleProduct = async () => {
      try {
        const res = await axiosApi.get(`/api/product/${params.name}`);
        const data = res.data;
        if (data.id === 0) throw Error;
        setCourse({
          id: data.id,
          productName: data.product_name,
          productDetail: data.product_detail,
          productPrice: data.product_price,
          productImage: data.product_image,
          category: data.fk_category_id,
          categoryName: data.category_name,
          slug: data.slug,
        });
      } catch (error) {
        if (error) navigate('/nowhere');
      } finally {
        setGetSingleCourse(false);
      }
    };
    getSingleProduct();
  }, [navigate, params, getSingleCourse]);

  useEffect(() => {
    if (location) return setCoursesApi(location?.state?.dataCourses);
    const getDataCourse = async () => {
      try {
        const res = await axiosApi.get('/api/product');
        const data = res.data;

        setCoursesApi(
          data?.map((data) => {
            return {
              id: data.id,
              productName: data.product_name,
              productPrice: data.product_price,
              productImage: data.product_image,
              category: data.fk_category_id,
              categoryName: data.category_name,
              slug: data.slug,
            };
          })
        );
      } catch (e) {
        if (e) navigate('/nowhere');
      }
    };
    getDataCourse();
  }, [location, navigate]);

  useEffect(() => {
    if (course.id === 0) return;
    document.title = `Soup Project | Course Detail : ${course.productName}`;
    console.log(course.id);
  }, [course.id, course.productName]);

  const buyNowToggle = () => {
    const checkLocal = localStorage.getItem('buynow');
    if (checkLocal) localStorage.removeItem('buynow');
    const data = {
      fk_product_id: course.id,
      schedule: new Date(Date.parse(date))
        .toLocaleString('fr-CA', {
          timeZone: 'Asia/Jakarta',
        })
        .slice(0, 10),
    };
    const currentMyClassToFilter = currentMyClass?.map((cur) => ({
      ...cur,
      schedule: cur.schedule.slice(0, 10),
    }));
    const filterOrder = filterdataToArray(currentMyClassToFilter, data);
    if (filterOrder) return setAlreadyOrderd(true);
    navigate('/checkout/buynow', {
      state: {
        ...course,
        schedule: new Date(Date.parse(date))
          .toLocaleString('fr-CA', {
            timeZone: 'Asia/Jakarta',
          })
          .slice(0, 10),
      },
    });
    localStorage.setItem(
      'buynow',
      JSON.stringify({ ...course, schedule: date })
    );
  };

  const inputDataTocart = () => {
    const data = {
      fk_product_id: course.id,
      schedule: new Date(Date.parse(date))
        .toLocaleString('fr-CA', {
          timeZone: 'Asia/Jakarta',
        })
        .slice(0, 10),
    };
    const filterCurrent = filterdataToArray(currentCart, data);
    const filterOrder = filterdataToArray(currentMyClass, data);

    if (filterCurrent || filterOrder) return setalreadyAdded(true);

    const postNewProductToCart = async () => {
      try {
        const res = await axiosApi.post(
          '/api/cartProduct',
          JSON.stringify(data)
        );

        if (res) {
          setUpdateOnCart(true);
          setAdded(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    postNewProductToCart();
  };

  if (!course)
    return (
      <Box
        display="flex"
        height={1}
        flexGrow={1}
        justifyContent="center"
        flexDirection="column"
      >
        <CircularProgress color="inherit" />
      </Box>
    );

  return (
    <Box
      display="flex"
      height={1}
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
    >
      {getSingleCourse && (
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 1,
              flexWrap: { xs: 'wrap', lg: 'nowrap' },
              my: '80px',
            }}
          >
            <Skeleton variant="rounded" width={350} height={300} />
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Skeleton width={350} height={300} />
            </Stack>
          </Box>
        </Container>
      )}
      {!getSingleCourse && (
        <>
          <Container maxWidth="lg">
            <Card
              sx={{
                border: 'none',
                boxShadow: 'none',
                display: 'flex',
                alignItems: ' flex-start',
                height: 'max-content',
                my: '40px',
                flexWrap: { xs: 'wrap', lg: 'nowrap' },
                gap: { xs: '20px', lg: '0' },
              }}
            >
              <CardMedia
                sx={{
                  objectFit: 'contain',
                  width: { xs: '100%', md: '400px' },
                }}
                component="img"
                alt={course.productName}
                image={course.productImage}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  px: { lg: 5 },
                }}
                width={1}
              >
                <CardContent
                  sx={{
                    p: 0,
                    '&:last-child': { pb: 0 },
                  }}
                >
                  <Typography variant="subtitle1">
                    {course.categoryName}
                  </Typography>
                  <Typography variant="h4" fontWeight="bold">
                    {course.productName}
                  </Typography>
                  <Typography variant="h4">
                    {formatCurrency(course.productPrice)}
                  </Typography>
                </CardContent>
                {_.isEmpty(token) ? (
                  <Typography
                    my={2}
                    variant="h6"
                    color={'primary.main'}
                    fontWeight={500}
                  >
                    Login before choosing schedule
                  </Typography>
                ) : (
                  <CardActions
                    sx={{
                      p: 0,
                      m: 0,
                      '>:not(:first-of-type)': { ml: 0 },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      mt: 3,
                      gap: '25px',
                    }}
                  >
                    <SelectionCourseDetail
                      date={date}
                      setDate={setDate}
                      setalreadyAdded={setalreadyAdded}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '25px',
                        margin: 0,
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{ width: { xs: '140px', lg: '230px' } }}
                        onClick={inputDataTocart}
                        disabled={!date || updateOnCart}
                      >
                        {updateOnCart ? (
                          <CircularProgress size="1.75rem" color="inherit" />
                        ) : (
                          <Typography variant="body">Add to cart</Typography>
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ width: { xs: '140px', lg: '230px' } }}
                        disabled={!date}
                        onClick={buyNowToggle}
                      >
                        {updateOnCart ? (
                          <CircularProgress size="1.75rem" color="inherit" />
                        ) : (
                          <Typography variant="body">Buy Now</Typography>
                        )}
                      </Button>
                    </Box>
                  </CardActions>
                )}
              </Box>
            </Card>
          </Container>
          <Container maxWidth="lg">
            <Box mb={{ xs: '20px', lg: '80px' }}>
              <Typography variant="h5" mb={'16px'} fontWeight="600">
                Description
              </Typography>
              <Readmore detailText={course.productDetail} />
            </Box>
          </Container>
        </>
      )}
      <Divider />
      <Container maxWidth="lg">
        <Box mt={{ xs: '20px', lg: '80px' }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="600"
            mb={{ xs: '20px', lg: '80px' }}
          >
            Another menu in Class
          </Typography>
          <CoursesList dataCourses={coursesApi} courseDetail={course} />
        </Box>
      </Container>
      <Snackbar
        open={added}
        autoHideDuration={2000}
        onClose={() => setAdded(false)}
      >
        <Alert
          onClose={() => setAdded(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Course added to cart
        </Alert>
      </Snackbar>
      <Snackbar
        open={alreadyAdded || alreadyOrderd}
        autoHideDuration={2000}
        onClose={() => {
          setalreadyAdded(false);
          setAlreadyOrderd(false);
        }}
      >
        <Alert
          onClose={() => {
            setalreadyAdded(false);
            setAlreadyOrderd(false);
          }}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {alreadyOrderd && 'Cant process schedule that already on your class'}
          {alreadyAdded && 'Schedule already on your cart or on your class'}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CourseDetail;
