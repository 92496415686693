// How to use formatDate("2022-07-27");
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
};

// How to use formatCurrency(amount);
function formatCurrency(amount) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
    useGrouping: true,
    currencyDisplay: 'symbol',
  }).format(amount).replace("Rp", "IDR ");
}

const formatDateNoDay = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
};

function formatNoInvoice(num) {
  const paddedNum = num.toString().padStart(5, "0");
  return `SOU${paddedNum}`;
}

export { formatDate, formatCurrency, formatDateNoDay, formatNoInvoice };
