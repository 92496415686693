import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import axiosApi from '../utils/axiosApi';

function EmailConfirmation() {
  const [searchParams] = useSearchParams();
  const [verifyUser, setVerifyUser] = useState(undefined);
  const navigate = useNavigate();

  const token = searchParams.get('token');
  const firstRender = useRef(false);

  useEffect(() => {
    document.title = 'Soup Project | Email Confirmation';
  }, []);

  useEffect(() => {
    if (firstRender.current) return;
    firstRender.current = true;

    const data = { token };
    axiosApi
      .post('api/email-confirmation/verify', JSON.stringify(data))
      .then((res) => {
        if (res.data.status === true) {
          setVerifyUser(true);
        } else {
          setVerifyUser(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setVerifyUser(false);
      });
  }, [verifyUser, token]);

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      p={5}
    >
      {verifyUser === false && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box component="img" src="../assets/error.svg" height={200} />
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography fontWeight="500" align="center" mt={1}>
              Email Confirmation Not Success
            </Typography>
            <Typography align="center" mt={1}>
              Email confirmation not success. please try again.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              startIcon={<HomeIcon />}
              sx={{ py: 2, px: 3, textTransform: 'none', mt: 5 }}
              onClick={() => navigate('/')}
            >
              <Typography variant="subtitle1" fontWeight="600">
                Login Here
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
      {verifyUser && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box component="img" src="../assets/success.svg" height={200} />
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography fontWeight="500" align="center" mt={1}>
              Email Confirmation Success
            </Typography>
            <Typography align="center" mt={1}>
              Congratulations! your email has already confirmed.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              startIcon={<HomeIcon />}
              sx={{ py: 2, px: 3, textTransform: 'none', mt: 5 }}
              onClick={() => navigate('/login')}
            >
              <Typography variant="subtitle1" fontWeight="600">
                Login Here
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default EmailConfirmation;
