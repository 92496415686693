// Minggu 11:25 ListCheckout
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { formatCurrency, formatDate } from '../utils';
import axiosApi from '../utils/axiosApi';
import TransactionContext from '../utils/TransactionContext';

const ListCheckout = ({
  checked,
  setChecked,
  data,
  setData,
  setTotal,
  total,
}) => {
  const [checkAll, setCheckAll] = useState(false);
  const { setUpdateOnCart } = useContext(TransactionContext);

  const handleCheckAll = (event) => {
    setCheckAll(!checkAll);
    setChecked(!checkAll ? data.map((e) => e.guid) : []);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setCheckAll(newChecked.length === data.length);
  };

  const handleDelete = async (id) => {
    try {
      const res = await axiosApi.delete(`/api/cartproduct/${id}`);
      if (res) setUpdateOnCart(true);
    } catch (error) {
      console.log(error);
    }

    // setData(data.filter((e) => e.guid !== id))
  };

  return (
    <List sx={{ width: '100%', maxWidth: 'lg', bgcolor: 'background.paper' }}>
      {data.length > 0 && (
        <>
          <ListItem disablePadding>
            <ListItemButton dense onClick={() => handleCheckAll(checkAll)}>
              <Checkbox
                checked={checkAll}
                edge="start"
                tabIndex={-1}
                color="secondary"
                disableRipple
                inputProps={{
                  'aria-labelledby': 'checkbox-list-label-checkAll',
                }}
              />
              <ListItemText sx={{ pl: 1 }}>
                <Typography
                  variant="h6"
                  color="gray1"
                  sx={{
                    lineHeight: '24px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Pilih Semua
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
        </>
      )}

      {data.map((e) => {
        const labelId = `checkbox-list-label-${e.guid}`;

        return (
          <div key={e.guid}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="Delete"
                  onClick={() => handleDelete(e.guid)}
                >
                  <DeleteForeverIcon color="error" fontSize="large" />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={handleToggle(e.guid)}
                dense
              >
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  checked={checked.includes(e.guid)}
                  color="secondary"
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                <ListItemAvatar sx={{ py: 3, pr: 2, pl: 1, maxWidth: '200' }}>
                  <Box
                    component="img"
                    sx={{ width: { xs: 100, sm: 150, md: 150, lg: 200 } }}
                    alt={e.couse_name}
                    src={e.product_image}
                  />
                </ListItemAvatar>
                <ListItemText sx={{ gap: 1 }}>
                  <Typography
                    variant="subtitle1"
                    color="gray3"
                    sx={{
                      lineHeight: '20px',
                      fontWeight: '400',
                      mb: 0.5,
                    }}
                  >
                    {e.category_name}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="#333"
                    sx={{
                      lineHeight: '29px',
                      fontWeight: '600',
                      mb: 1,
                    }}
                  >
                    {e.product_name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="gray2"
                    sx={{
                      lineHeight: '20px',
                      fontWeight: '400',
                      mb: 1,
                    }}
                  >
                    {'Schedule : ' + formatDate(e.schedule)}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{
                      lineHeight: '24px',
                      fontWeight: '600',
                    }}
                  >
                    {formatCurrency(e.product_price)}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        );
      })}

      <Box sx={{ height: '10vh' }} />
    </List>
  );
};

export default ListCheckout;
