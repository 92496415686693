import { Box, Container, Paper, Typography } from '@mui/material';
import React, { useContext, useState, useEffect, useNavigate } from 'react';
import DataTableDashboardInvoice from '../../components/DataTableDashboardInvoice';
import TransactionContext from '../../utils/TransactionContext';
import UserContext from '../../utils/UserContext'
import jwtDecode from 'jwt-decode';
import axiosApi from '../../utils/axiosApi';
import { sortBy } from 'lodash'

const columns = ['No', 'User Name', 'No. Invoice', 'Date', 'Total Course', 'Total Price', 'Action']

function DashboardInvoice() {
  const { currentInvoice, setCurrentInvoice } = useContext(TransactionContext);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])

  const { currentUser, setCurrentUser } = useContext(UserContext);
  // const navigate = useNavigate();

  const userData = currentUser ? jwtDecode(currentUser) : null;
  const role = userData ? userData.role : null;

  useEffect(() => {
    document.title = 'Soup Project | Dashboard Invoice'

    setLoading(true)
    const getData = async () => {
      try {
        if (role === 'admin') {
          const getAllInvoice = await axiosApi.get('/api/order')
          const getUserFromInvoice = getAllInvoice.data.map((e) => { return e.fk_user_guid })

          const getUserApi = await axiosApi.get('/api/user/all')

          const invoicesWithName = getAllInvoice.data.map(invoice => {
            const user = getUserApi.data.find(user => user.guid === invoice.fk_user_guid);
            return { ...invoice, name: user ? user.name : 'Unknown' };
          });

          return setData(invoicesWithName);
        } else if (role === 'basic') {
          return setData(currentInvoice)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [])



  const sortedData = sortBy(data, 'no_invoice').reverse()
  return (
    <Paper>
      <Box px={3} py={4}>
        <Typography align="center" variant="h5">
          Invoice
        </Typography>
        <Box display='flex' flexDirection='column' justifyContent='space-between' mt={5} mx={1} flexGrow={1} >
          <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
            <DataTableDashboardInvoice
              columns={columns}
              data={sortedData}
              loading={loading}
              invoice />
          </Container>
        </Box >
      </Box>
    </Paper>

  );
}

export default DashboardInvoice;
