import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import axiosApi from '../utils/axiosApi';

function DialogDashboardPaymentMethodEdit({
  openDialog,
  setOpenDialog,
  data,
  setSnackbar,
}) {
  const [newData, setNewData] = useState({
    id: 0,
    name: '',
    logo: '',
    active: false,
  });
  const [errorForm, setErrorForm] = useState({ name: false, nameMessage: '' });

  useEffect(() => {
    setNewData((prev) => ({
      ...prev,
      id: data.id,
      name: data.name,
      logo: data.logo,
      active: data.active,
    }));
  }, [data]);

  const onPickImage = (e) => {
    const tempImage = e.target.files[0];
    let reader = new FileReader();

    reader.onload = function () {
      console.log(reader.result);
      setNewData((prev) => ({ ...prev, logo: reader.result }));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

    reader.readAsDataURL(tempImage);
  };

  const onChangeName = (e) => {
    setNewData((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleChangeActive = () => {
    setNewData((prev) => ({ ...prev, active: !prev.active }));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickChange = () => {
    if (newData.name === '') {
      return setErrorForm((prev) => ({
        ...prev,
        name: true,
        message: 'Name must be filled',
      }));
    }
    const data = {
      name: newData.name,
      logo: newData.logo,
      active: newData.active,
    };
    axiosApi
      .put(`api/paymentmethod/${newData.id}`, data)
      .then((res) => {
        console.log(res);
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'success',
          message: `Success edit data ${newData.name}`,
        }));
        handleCloseDialog();
      })
      .catch((err) => {
        console.log(err);
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'error',
          message: err.response.data.message,
        }));
      });
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Change payment method information</DialogTitle>
      <DialogContent>
        <DialogContentText>Change payment method details</DialogContentText>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Avatar
            src={newData.logo}
            style={{
              margin: '10px',
              width: '60px',
              height: '60px',
            }}
          />
          <Button variant="contained" component="label">
            <Typography fontSize={12}>Change Logo</Typography>
            <input type="file" hidden onChange={onPickImage} accept="image/*" />
          </Button>
        </Box>
        <TextField
          autoFocus
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={newData.name}
          onChange={onChangeName}
          error={errorForm.name}
          helperText={errorForm.nameMessage}
        />

        <Box mt={3}>
          <FormLabel component="legend">Active Payment Method</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Switch name="active" />}
              checked={newData.active ? newData.active : false}
              onChange={handleChangeActive}
              label={newData.active ? 'Yes' : 'No'}
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button onClick={onClickChange} disabled={newData.name === ''}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDashboardPaymentMethodEdit;
