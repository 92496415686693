import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosApi from '../utils/axiosApi';

function ResetPasswordOtp() {
  let [searchParams] = useSearchParams();
  const [otp, setOtp] = useState('');
  const [dataApi, setDataApi] = useState({
    error: false,
    success: false,
    message: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Soup Project | Reset Password';
  }, []);

  const onChangeOtp = (newValue) => {
    setOtp(newValue);
    setDataApi((prev) => ({ ...prev, error: false, message: '' }));
  };

  const validateInputOtp = (input) => {
    const pattern = /\d/g;
    return pattern.test(input) || input === '';
  };

  const onCompleteOtp = (value) => {
    const guid = searchParams.get('guid');
    const data = { guid, otp: value };
    axiosApi
      .post('api/reset-password', data)
      .then((res) => {
        if (res.data.data) {
          navigate(`/reset-password?guid=${guid}&token=${res.data.token}`);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        setDataApi((prev) => ({
          ...prev,
          error: true,
          message: err.response.data.message,
        }));
      });
  };

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      p={5}
    >
      <Box display="flex" flexDirection="column" width="100%" maxWidth="sm">
        <Box>
          <Typography fontWeight={500} variant="h5">
            We've sent you an email
          </Typography>
          <Typography variant="subtitle1">Input your OTP code</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <MuiOtpInput
            value={otp}
            onChange={onChangeOtp}
            length={6}
            onComplete={onCompleteOtp}
            validateChar={validateInputOtp}
            gap="5px"
          />
        </Box>
        <Box mt={2}>
          {dataApi.error === true && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {dataApi.message}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPasswordOtp;
