import { Box } from '@mui/material';
import React from 'react';

function Category() {
  return (
    <Box
      display="flex"
      height={1}
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
    ></Box>
  );
}

export default Category;
