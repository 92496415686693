import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  CircularProgress,
  DialogTitle,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosApi from '../utils/axiosApi';

export default function DialogPaymentMethod({
  open,
  setOpen,
  selectedPayment,
  setSelectedPayment,
  onclick,
  isPay
}) {
  const [dataPayment, setDataPayment] = useState([]);
  useEffect(() => {
    // console.log('initial render')
    const getListPaymentMethod = async () => {
      try {
        const res = await axiosApi.get('/api/paymentmethod');
        return setDataPayment(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getListPaymentMethod();
  }, []);

  const handleListItemClick = (event, id) => {
    setSelectedPayment(id);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPayment(null);
    console.log('close');
  };

  // console.log(coba)

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container maxWidth="sm">
        <DialogTitle
          variant="h6"
          color="#41454D"
          align="center"
          fontFamily="Poppins"
          lineHeight="30px"
          fontWeight="500"
        >
          Select Payment Method
        </DialogTitle>
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <List component="nav" aria-label="main mailbox folders">
            {dataPayment.map((e, i) => (
              <ListItemButton
                key={e.id}
                selected={selectedPayment === e.id}
                onClick={(event) => handleListItemClick(event, e.id)}
              >
                <ListItemAvatar>
                  <Avatar src={e.logo} />
                </ListItemAvatar>
                <ListItemText>
                  <Typography
                    // variant='18px'
                    fontSize="18px"
                    color="#41454D"
                    lineHeight="27px"
                    fontWeight="500"
                  >
                    {e.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Box display="flex" justifyContent="space-evenly" my={3} gap={3}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disableElevation
            sx={{
              px: 5,
              py: 1.5,
              textTransform: 'none',
              borderRadius: 3,
              border: '1px solid primary',
            }}
            onClick={handleClose}
          >
            <Typography variant="subtitle1" fontWeight="600">
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={selectedPayment === null}
            disableElevation
            sx={{
              px: 5,
              py: 1.5,
              textTransform: 'none',
              borderRadius: 3,
              border: '1px solid primary',
            }}
            onClick={onclick}
          >
            <Typography variant="subtitle1" fontWeight="600">
              {isPay ? (
                <CircularProgress size="1.5rem" sx={{ px: 3, }} color="inherit" />
              ) : (
                'Pay Now'
              )}
            </Typography>
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
}
