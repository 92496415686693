import {
  Box,
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderDashboard from './HeaderDashboard';

function MainLayoutDashboard() {
  let theme = createTheme({
    palette: {
      primary: {
        main: '#FABC1D',
      },
      secondary: {
        main: '#5B4947',
      },
      white: {
        main: '#FFF',
      },
      gray1: '#333',
      gray2: '#4F4F4F',
      gray3: '#828282',
      background: {
        default: 'rgb(248 250 252);',
      },
    },
    typography: {
      fontFamily: ['Montserrat', 'Poppins', 'Inter'].join(','),
      allVariants: {
        color: '#4F4F4F',
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HeaderDashboard>
          <Box>
            <Outlet />
          </Box>
        </HeaderDashboard>
      </ThemeProvider>
    </Box>
  );
}

export default MainLayoutDashboard;
