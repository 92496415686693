import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import axiosApi from '../utils/axiosApi';
import UserContext from '../utils/UserContext';
import TransactionContext from '../utils/TransactionContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ status: null, message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState({
    email: false,
    emailMessage: '',
    password: false,
    passwordMessage: '',
  });
  const [sendingEmail, setSendingEmail] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    status: 'success',
    message: '',
  });

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { setCurrentCart, setCurrentMyClass, setCurrentInvoice } =
    useContext(TransactionContext);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Soup Project | Login';
  }, []);

  useEffect(() => {
    const jwt = currentUser;
    if (jwt) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorForm((prev) => ({ ...prev, email: false, emailMessage: '' }));
    setError((prev) => ({ ...prev, status: null, message: '' }));
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorForm((prev) => ({ ...prev, password: false, passwordMessage: '' }));
    setError((prev) => ({ ...prev, status: null, message: '', data: [] }));
  };

  const onClickLogin = async () => {
    const data = { email, password };
    if (email === '') {
      setErrorForm((prev) => ({
        ...prev,
        email: true,
        emailMessage: 'Email must be filled.',
      }));
    }
    if (password === '') {
      setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'Password must be filled.',
      }));
    }
    if (email === '' || password === '') return;

    try {
      setIsLoading(true);
      const apiDataUser = await axiosApi.post(
        '/api/auth/login',
        JSON.stringify(data)
      );

      setCurrentUser(apiDataUser.data);
      localStorage.setItem('auth', apiDataUser.data);

      const apiDataMyClass = await axiosApi.get('api/myclass/user');
      setCurrentMyClass(apiDataMyClass.data);
      localStorage.setItem('my-class', JSON.stringify(apiDataMyClass.data));

      const apiDataCart = await axiosApi.get('api/cartproduct/user');
      setCurrentCart(apiDataCart.data);
      localStorage.setItem('cart', JSON.stringify(apiDataCart.data));

      const apiDataInvoice = await axiosApi.get('api/order/user');
      setCurrentInvoice(apiDataInvoice.data);
      localStorage.setItem('invoice', JSON.stringify(apiDataInvoice.data));

      setError((prev) => ({ ...prev, status: false, message: '' }));
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data);
      console.log(err.response.status);
      setError((prev) => ({
        ...prev,
        status: true,
        message: err.response.data.message,
        data: err.response.data.data,
      }));
    }
  };

  const onRequestEmailVerification = () => {
    setSendingEmail(true);
    axiosApi
      .post('api/email-confirmation/request', { email })
      .then((res) => {
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'success',
          message: 'Success sending email confirmation',
        }));
        setSendingEmail(false);
      })
      .catch((err) => {
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'error',
          message: err.response.data.message,
        }));
        setSendingEmail(false);
      });
  };

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      p={5}
    >
      {currentUser === false && (
        <Box display="flex" flexDirection="column" width="100%" maxWidth="sm">
          <Box>
            <Typography fontWeight={500} variant="h5">
              Welcome Back! Chef
            </Typography>
            <Typography variant="subtitle1">Please login first</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              sx={{ mt: 2 }}
              onChange={onChangeEmail}
              error={errorForm.email}
              helperText={errorForm.emailMessage}
            />
            <TextField
              id="password"
              label="Pasword"
              variant="outlined"
              type="password"
              sx={{ mt: 2 }}
              onChange={onChangePassword}
              error={errorForm.password}
              helperText={errorForm.passwordMessage}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onClickLogin();
              }}
            />
          </Box>
          <Box mt={2}>
            {error.status === true && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
                <br />
                <br />
                {error.data.length > 0 &&
                  error.data[0] === 'Not verified.' &&
                  'You can request email confirmation by '}
                {error.data.length > 0 && error.data[0] === 'Not verified.' && (
                  <Link to="#" onClick={() => onRequestEmailVerification()}>
                    Click Here
                  </Link>
                )}
                {sendingEmail && (
                  <CircularProgress
                    size={12}
                    sx={{ ml: '8px' }}
                    color="secondary"
                  />
                )}
              </Alert>
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">
              Forgot Password?{' '}
              <Box
                component={Link}
                to="/reset-password"
                sx={{ textDecoration: 'none', color: '#2F80ED' }}
              >
                Click Here
              </Box>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              sx={{ mt: 2, width: 125, height: 40 }}
              onClick={onClickLogin}
            >
              {isLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                'Login'
              )}
            </Button>
          </Box>
          <Box mt={3}>
            <Typography textAlign="center" variant="subtitle1">
              Don't have account?{' '}
              <Box
                component={Link}
                to="/register"
                sx={{ textDecoration: 'none', color: '#2F80ED' }}
              >
                Sign Up here
              </Box>
            </Typography>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.status}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Login;
