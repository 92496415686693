import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axiosApi from '../utils/axiosApi';

function Register() {
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errorForm, setErrorForm] = useState({
    name: false,
    nameMessage: '',
    email: false,
    emailMessage: '',
    password: false,
    passwordMessage: '',
    confirmPassword: false,
    confirmPasswordMessage: '',
  });
  const [error, setError] = useState({ status: null, message: '' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = 'Soup Project | Register';
  }, []);

  const onChangeName = (e) => {
    setData((prev) => ({ ...prev, name: e.target.value }));
    setErrorForm((prev) => ({ ...prev, name: false, nameMessage: '' }));
  };

  const onChangeEmail = (e) => {
    setData((prev) => ({ ...prev, email: e.target.value }));
    setErrorForm((prev) => ({ ...prev, email: false, emailMessage: '' }));
  };

  const onChangePassword = (e) => {
    setData((prev) => ({ ...prev, password: e.target.value }));
    setErrorForm((prev) => ({ ...prev, password: false, passwordMessage: '' }));
    setError((prev) => ({ ...prev, status: null, message: '' }));
  };

  const onChangeConfirmPassword = (e) => {
    setData((prev) => ({ ...prev, confirmPassword: e.target.value }));
    setErrorForm((prev) => ({
      ...prev,
      confirmPassword: false,
      confirmPasswordMessage: '',
    }));
    setError((prev) => ({ ...prev, status: null, message: '' }));
  };

  const onClickRegister = async () => {
    if (data.name === '') {
      setErrorForm((prev) => ({
        ...prev,
        name: true,
        nameMessage: 'Name must be filled',
      }));
    }
    if (data.email === '') {
      setErrorForm((prev) => ({
        ...prev,
        email: true,
        emailMessage: 'Email must be filled',
      }));
    }
    if (data.password === '') {
      setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'Password must be filled',
      }));
    }
    if (data.confirmPassword === '') {
      setErrorForm((prev) => ({
        ...prev,
        confirmPassword: true,
        confirmPasswordMessage: 'Confirm Password must be filled',
      }));
    }
    if (
      data.name === '' ||
      data.email === '' ||
      data.password === '' ||
      data.confirmPassword === ''
    ) {
      return;
    }

    if (data.password.length < 8) {
      return setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'Minimum password is 8 character',
      }));
    }

    if (data.password !== data.confirmPassword) {
      return setErrorForm((prev) => ({
        ...prev,
        confirmPassword: true,
        confirmPasswordMessage: 'Confirm password not same as password',
      }));
    }

    try {
      setIsLoading(true);
      const apiDataRegister = await axiosApi.post(
        'api/auth/register',
        JSON.stringify(data)
      );
      console.log(apiDataRegister);
      if (apiDataRegister.data.guid && apiDataRegister.data.expired_date) {
        setError((prev) => ({ ...prev, status: false, message: '' }));
        setIsLoading(false);
        setData((prev) => ({
          ...prev,
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
        }));
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data);
      console.log(err.response.status);
      setError((prev) => ({
        ...prev,
        status: true,
        message: err.response.data.message,
      }));
    }
  };

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      p={5}
    >
      <Box display="flex" flexDirection="column" width="100%" maxWidth="sm">
        <Box>
          <Typography fontWeight={500} variant="h5">
            Are you ready become a professional cheff?
          </Typography>
          <Typography variant="subtitle1">Please register first</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            sx={{ mt: 2 }}
            value={data.name}
            onChange={onChangeName}
            error={errorForm.name}
            helperText={errorForm.nameMessage}
          />
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            sx={{ mt: 2 }}
            value={data.email}
            onChange={onChangeEmail}
            error={errorForm.email}
            helperText={errorForm.emailMessage}
          />
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            type="password"
            sx={{ mt: 2 }}
            value={data.password}
            onChange={onChangePassword}
            error={errorForm.password}
            helperText={errorForm.passwordMessage}
          />
          <TextField
            id="confirm-password"
            label="Confirm Password"
            variant="outlined"
            type="password"
            sx={{ mt: 2 }}
            value={data.confirmPassword}
            onChange={onChangeConfirmPassword}
            error={errorForm.confirmPassword}
            helperText={errorForm.confirmPasswordMessage}
          />
        </Box>
        <Box mt={2}>
          {error.status === true && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )}
          {error.status === false && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Your account is created —{' '}
              <strong>Please check your email to activate account.</strong>
            </Alert>
          )}
        </Box>
        <Box display="flex" justifyContent="end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            sx={{ mt: 2, width: 125, height: 40 }}
            onClick={onClickRegister}
          >
            {isLoading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              'Sign Up'
            )}
          </Button>
        </Box>
        <Box mt={3}>
          <Typography textAlign="center" variant="subtitle1">
            Have Account?{' '}
            <Box
              component={Link}
              to="/login"
              sx={{ textDecoration: 'none', color: '#2F80ED' }}
            >
              Login Here
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Register;
