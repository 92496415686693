import React, { useEffect } from 'react';
import { Paper, Box, Breadcrumbs, Container, Typography } from '@mui/material'
import DataTableDashboardInvoice from '../../components/DataTableDashboardInvoice';
import { Link, useLocation } from 'react-router-dom';
// import data from '../data/dataTestingInvoice.json';

const columns = ['No', 'Course Name', 'Type', 'Schedule', 'Price']

function DashboardInvoiceDetail() {
  const location = useLocation()
  const course = location.state.course
  const noInvoice = location.state.noInvoice
  const date = location.state.date
  const totalPrice = location.state.totalPrice
  const name = location.state.name

  useEffect(() => {
    document.title = 'Soup Project | Dashboard Invoice Detail'
  }, [])

  return (
    <Paper>
      <Box px={3} py={4}>
        <Typography align="center" variant="h5">
          Detail Invoice
        </Typography>
        <Box display='flex' flexDirection='column' justifyContent='space-between' mt={5} mx={1} flexGrow={1} >
          <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
            <Breadcrumbs aria-label="breadcrumbs" separator=">"
              size="md">
              {['Invoice'].map((item) => (
                <Typography
                  sx={{ textDecoration: 'none' }}
                  key={item}
                  component={Link}
                  to={item === 'Home' ? '/dashboard' : `/dashboard/${item.toLowerCase()}`}
                  color='gray3'
                  variant='body1'
                  fontWeight={600}
                  lineHeight={2}>{item}</Typography>
              ))}

              <Typography color='secondary'
                variant='body1' sx={{
                  lineHeight: '20px',
                  fontWeight: '600',
                }}>Invoice Detail</Typography>
            </Breadcrumbs>

            <DataTableDashboardInvoice
              columns={columns}
              data={course}
              noInvoice={noInvoice}
              date={date}
              totalPrice={totalPrice}
              name={name}
              invoiceDetail
            />
          </Container>
        </Box >
      </Box>
    </Paper>

  )
}

export default DashboardInvoiceDetail;
