import { Box, Typography } from '@mui/material';
import React from 'react';

function EmptyList() {
  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Box component="img" src="../assets/empty_list.svg" height={200} />
        <Typography
          variant="56"
          color="gray3"
          sx={{
            lineHeight: '29px',
            fontWeight: '500',
            mb: 1,
          }}
        >
          Oh No, Nothing On Ur List, Please Add Something
        </Typography>
      </Box>
    </Box>
  );
}

export default EmptyList;
