import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosApi from '../utils/axiosApi';
import isEmail from '../utils/isEmail';

function ResetPasswordRequest() {
  const [email, setEmail] = useState('');
  const [errorForm, setErrorForm] = useState({
    email: false,
    emailMessage: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState({ status: null, message: '' });

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorForm((prev) => ({ ...prev, email: false, emailMessage: '' }));
    setError((prev) => ({ ...prev, status: null, message: '' }));
  };

  const onClickConfirm = () => {
    if (email === '') {
      return setErrorForm((prev) => ({
        ...prev,
        email: true,
        emailMessage: 'Email must be filled',
      }));
    }
    if (!isEmail(email)) {
      return setErrorForm((prev) => ({
        ...prev,
        email: true,
        emailMessage: 'Email address is not valid',
      }));
    }

    setIsLoading(true);
    axiosApi
      .post('api/reset-password/request', JSON.stringify({ email }))
      .then((res) => {
        if (res.data.guid) {
          navigate(`/reset-password/otp?guid=${res.data.guid}`);
        }
      })
      .catch((err) => {
        setError((prev) => ({
          ...prev,
          status: false,
          message: err.response.data.data,
        }));
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <Box>
        <Typography fontWeight="500" variant="h5">
          Reset Password
        </Typography>
        <Typography variant="subtitle1">
          Send OTP code to your email address
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          sx={{ mt: 2 }}
          value={email}
          onChange={onChangeEmail}
          error={errorForm.email}
          helperText={errorForm.emailMessage}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onClickConfirm();
          }}
        />
      </Box>
      <Box mt={2}>
        {error.status === false && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          disableElevation
          sx={{ mt: 2, px: 5, py: 1, borderRadius: 2 }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Box display="flex" justifyContent="end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            sx={{ mt: 2, borderRadius: 2, width: 135, height: 40 }}
            onClick={onClickConfirm}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              'Confirm'
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPasswordRequest;
