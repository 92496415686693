import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';

import { formatDate } from '../utils';

import TransactionContext from '../utils/TransactionContext';
import EmptyList from '../components/reusable/EmptyList.js';

function MyClass() {
  const { currentMyClass } = useContext(TransactionContext);
  const currentDisplay = currentMyClass
    ?.map((cur) => ({
      ...cur,
      schedule: new Date(Date.parse(cur.schedule)),
    }))
    .sort(
      (d1, d2) =>
        new Date(d1.schedule).getTime() - new Date(d2.schedule).getTime()
    );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
      className="box-cart"
      mt={2}
    >
      <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
        {currentMyClass.length === 0 ? (
          <EmptyList />
        ) : (
          <List
            sx={{ width: '100%', maxWidth: 'lg', bgcolor: 'background.paper' }}
          >
            {currentDisplay?.map((e) => {
              return (
                <div key={e.guid}>
                  <ListItem disablePadding>
                    <ListItemButton dense>
                      <ListItemAvatar sx={{ py: 3, pr: 3, maxWiidth: '200' }}>
                        <Box
                          component="img"
                          sx={{ width: { xs: 100, sm: 150, md: 150, lg: 200 } }}
                          alt={e.couse_name}
                          src={e.product_image}
                        />
                      </ListItemAvatar>
                      <ListItemText sx={{ gap: 1 }}>
                        <Typography
                          variant="subtitle1"
                          color="gray3"
                          sx={{
                            lineHeight: '20px',
                            fontWeight: '400',
                            mb: 0.5,
                          }}
                        >
                          {e.category_name}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="gray1"
                          sx={{
                            lineHeight: '29px',
                            fontWeight: '600',
                            mb: 1,
                          }}
                        >
                          {e.product_name}
                        </Typography>
                        <Typography
                          variant="h6"
                          color="primary"
                          sx={{
                            lineHeight: '24px',
                            fontWeight: '500',
                          }}
                        >
                          {'Schedule : ' + formatDate(e.schedule)}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        )}
      </Container>
    </Box>
  );
}

export default MyClass;
