import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import UserContext from '../../utils/UserContext';
import jwtDecode from 'jwt-decode';

function HeaderDashboard({ children }) {
  const [openNavbar, setOpenNavbar] = useState(false);

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const navigateHeader = (link = '') => {
    setOpenNavbar(false);
    navigate(`/${link}`);
  };

  const userData = currentUser ? jwtDecode(currentUser) : null;
  const role = userData ? userData.role : null;

  const logoutUser = () => {
    setOpenNavbar(false);
    localStorage.removeItem('auth');
    localStorage.removeItem('my-class');
    localStorage.removeItem('cart');
    localStorage.removeItem('buy-now');
    localStorage.removeItem('invoice');
    setCurrentUser(false);
  };

  const drawerWidth = 275;

  const ListItemSidebar = ({ text, path, buttonFunction, icon }) => {
    return (
      <ListItem>
        <ListItemButton
          onClick={buttonFunction ? buttonFunction : () => navigateHeader(path)}
        >
          <ListItemIcon sx={{ minWidth: '30px' }}>{icon}</ListItemIcon>
          <ListItemText>
            <Typography fontFamily="Montserrat" fontWeight={500}>
              {text}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  };

  const DrawerContent = () => {
    return (
      <>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              fontFamily="Poppins"
              component={'h4'}
              fontSize={20}
              textAlign="center"
              onClick={() => navigate('/')}
              sx={{ cursor: 'pointer' }}
            >
              Soup Project
            </Typography>
          </Box>
        </Toolbar>
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {(role === 'basic' || role === 'admin') && (
              <>
                <ListItemSidebar
                  text="Dashboard"
                  buttonFunction={() => navigate('/dashboard')}
                  icon={<HomeIcon />}
                />
                <Divider variant="middle" sx={{ bgcolor: 'white' }} />
              </>
            )}
            {role === 'admin' && (
              <ListItemSidebar
                text="Category"
                path="dashboard/category"
                icon={<BookmarkIcon />}
              />
            )}
            {(role === 'basic' || role === 'admin') && (
              <ListItemSidebar
                text="Course"
                path="dashboard/course"
                icon={<BookmarkIcon />}
              />
            )}
            {role === 'admin' && (
              <Divider variant="middle" sx={{ bgcolor: 'white' }} />
            )}
            {(role === 'basic' || role === 'admin') && (
              <ListItemSidebar
                text="Invoice"
                path="dashboard/invoice"
                icon={<ReceiptIcon />}
              />
            )}
            {role === 'admin' && (
              <ListItemSidebar
                text="Payment Method"
                path="dashboard/payment-method"
                icon={<CreditCardIcon />}
              />
            )}
            {(role === 'basic' || role === 'admin') && (
              <Divider variant="middle" sx={{ bgcolor: 'white' }} />
            )}
            {role === 'admin' && (
              <ListItemSidebar
                text="Users"
                path="dashboard/users"
                icon={<PersonIcon />}
              />
            )}
            {(role === 'basic' || role === 'admin') && (
              <ListItemSidebar
                text="Profile"
                path="dashboard/profile"
                icon={<PersonIcon />}
              />
            )}
            {role === 'admin' && (
              <Divider variant="middle" sx={{ bgcolor: 'white' }} />
            )}
          </List>
        </Box>
      </>
    );
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
          marginLeft: { xs: '0', md: `${drawerWidth}px` },
        }}
        color="primary"
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, paddingLeft: 2 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setOpenNavbar(!openNavbar)}
              color="inherit"
              sx={{ display: { sx: 'flex', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              mr: 2,
            }}
          >
            <IconButton
              onClick={() => {
                navigateHeader();
                logoutUser();
              }}
            >
              <LogoutIcon color="secondary" sx={{ fontSize: '18px' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={openNavbar}
        onClose={() => setOpenNavbar(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'none', md: 'flex' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            minWidth: drawerWidth,
          },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <DrawerContent />
      </Drawer>
      <Drawer
        variant="temporary"
        open={openNavbar}
        onClose={() => setOpenNavbar(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'flex', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            minWidth: drawerWidth,
          },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <DrawerContent type="mobile" />
      </Drawer>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        sx={{ ml: { xs: 0, md: `${drawerWidth}px` }, p: { xs: 2, sm: 5 } }}
      >
        {children}
      </Box>
    </>
  );
}

export default HeaderDashboard;
