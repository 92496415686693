import React, { useContext, useEffect } from 'react';
import { Box, Breadcrumbs, Container, Typography } from '@mui/material'
import DataTableInvoice from '../components/DataTableInvoice';
import { Link } from 'react-router-dom';
import data from '../data/dataTestingInvoice.json';
import TransactionContext from '../utils/TransactionContext';
import axiosApi from '../utils/axiosApi';
import { sortBy } from 'lodash';

const columns = ['No', 'No. Invoice', 'Date', 'Total Course', 'Total Price', 'Action']


function Invoice() {
  const { currentInvoice, setCurrentInvoice } = useContext(TransactionContext);

  useEffect(() => {
    document.title = 'Soup Project | Invoice';
  }, [])

  const sortedData = sortBy(currentInvoice, 'no_invoice').reverse()

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' mt={5} mx={1} flexGrow={1} >
      <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
        {console.log(currentInvoice)}
        <Breadcrumbs aria-label="breadcrumbs" separator=">"
          size="md">
          {['Home'].map((item) => (
            <Typography
              sx={{ textDecoration: 'none' }}
              key={item}
              component={Link}
              to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
              color='gray3'
              variant='body1'
              fontWeight={600}
              lineHeight={2}>{item}</Typography>
          ))}

          <Typography color='secondary'
            variant='body1' sx={{
              lineHeight: '20px',
              fontWeight: '600',
            }}>Invoice</Typography>
        </Breadcrumbs>
        {console.log(sortedData)}
        <DataTableInvoice
          title='Menu Invoice'
          columns={columns}
          data={sortedData}
          invoice />
      </Container>
    </Box >
  )
}

export default Invoice;
