import React, { useEffect, useState } from 'react';

import { Box, Container, Typography } from '@mui/material';

// Component
import CardInfoLandingPage from '../components/CardInfoLandingPage';
import CoursesList from '../components/reusable/CoursesList';
import BannerLandingPage from '../components/BannerLandingPage';
import CategoryList from '../components/CategoryList';

// Source Image For Header
// Data dummy courses
import axiosApi from '../utils/axiosApi';

const bannerImageMainHeader = `/assets/banner/main_header.png`;
const bannerImageBottom = `/assets/banner/main_header2.png`;

function Index() {
  const [coursesApi, setCoursesApi] = useState([]);
  const [gettingCourse, setGettingCourse] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [gettingCategory, setGettingCategory] = useState(false);

  useEffect(() => {
    const getDataCagetory = async () => {
      try {
        setGettingCategory(true);
        const res = await axiosApi.get('/api/category');
        const data = res.data;
        setCategoryList(
          data?.map((data) => {
            return {
              id: data.id,
              categoryName: data.category_name,
              categoryImage: data.category_image,
              slug: data.slug,
            };
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        setGettingCategory(false);
      }
    };

    const getDataCourse = async () => {
      try {
        setGettingCourse(true);
        const res = await axiosApi.get('/api/product');
        const data = res.data;
        setCoursesApi(
          data?.map((data) => {
            return {
              id: data.id,
              productName: data.product_name,
              productPrice: data.product_price,
              productImage: data.product_image,
              category: data.fk_category_id,
              categoryName: data.category_name,
              slug: data.slug,
            };
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        setGettingCourse(false);
      }
    };

    getDataCagetory();
    getDataCourse();
  }, []);

  useEffect(() => {
    document.title = `Soup Project`;
  }, []);

  return (
    <Box
      display="flex"
      height={1}
      flexGrow={1}
      justifyContent="flex-start"
      flexDirection="column"
    >
      <BannerLandingPage
        bannerImage={bannerImageMainHeader}
        align="center"
        headText="Be The Next Great Chef"
        bodyText="We are able to awaken your cooking skills to become a classy chef and
        ready to dive into the professional world"
        bannerSize="270px"
      />
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
          gap={{ xs: 1, lg: '40px' }}
        >
          <CardInfoLandingPage
            infoHeader={'200+'}
            infoBody={' Various cuisines available in professional class'}
          />
          <CardInfoLandingPage
            infoHeader={'50+'}
            infoBody={
              'A Chef Who is Realible and has his own Character in Cooking'
            }
          />
          <CardInfoLandingPage
            infoHeader={'30+'}
            infoBody={'Coorporate with trusted and upscale restaurants'}
          />
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          mt={{ xs: 2, lg: '56px' }}
          mb={{ xs: 2, lg: '80px' }}
          fontWeight="bold"
        >
          More Professional Class
        </Typography>
      </Container>

      <Container maxWidth="lg">
        <CoursesList
          dataCourses={coursesApi}
          landingPage
          gettingCourse={gettingCourse}
        />
      </Container>

      <BannerLandingPage
        bannerImage={bannerImageBottom}
        align="left"
        headText="Gets your benefit"
        bodyText="Et ullamco cupidatat veniam Lorem ipsum irure mollit."
        bannerSize="233px"
      />

      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          mt={{ xs: 2, lg: '77px' }}
          mb={{ xs: 2, lg: '80px' }}
          fontWeight="bold"
        >
          More Food Type as You Can Choose
        </Typography>
        <CategoryList
          dataCategory={categoryList}
          gettingCategory={gettingCategory}
        />
      </Container>
    </Box>
  );
}

export default Index;
