import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import axiosApi from '../utils/axiosApi';

function DialogDashboardPaymentMethodAdd({
  openDialog,
  setOpenDialog,
  setSnackbar,
}) {
  const [newData, setNewData] = useState({ name: '', logo: '' });
  const [errorForm, setErrorForm] = useState({ name: false, nameMessage: '' });

  const onPickImage = (e) => {
    const tempImage = e.target.files[0];
    let reader = new FileReader();

    reader.onload = function () {
      console.log(reader.result);
      setNewData((prev) => ({ ...prev, logo: reader.result }));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

    reader.readAsDataURL(tempImage);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewData((prev) => ({ ...prev, name: '', logo: '' }));
  };

  const onChangeName = (e) => {
    setNewData((prev) => ({ ...prev, name: e.target.value }));
  };

  const onClickAdd = () => {
    if (newData.name === '') {
      return setErrorForm((prev) => ({
        ...prev,
        name: true,
        nameMessage: 'Name must be filled',
      }));
    }
    if (newData.logo === '') {
      return setSnackbar((prev) => ({
        ...prev,
        open: true,
        status: 'error',
        message: 'You must select image for payment method',
      }));
    }
    const data = { name: newData.name, logo: newData.logo };
    axiosApi
      .post('api/paymentmethod', data)
      .then((res) => {
        console.log(res);
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'success',
          message: `Success add payment method ${newData.name}`,
        }));
        handleCloseDialog();
      })
      .catch((err) => {
        console.log(err.response.data);
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'error',
          message: err.response.data.message,
        }));
      });
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>New payment method</DialogTitle>
      <DialogContent>
        <DialogContentText>You can create new payment method</DialogContentText>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Avatar
            src={newData.logo}
            style={{
              margin: '10px',
              width: '60px',
              height: '60px',
            }}
          />
          <Button variant="contained" component="label">
            <Typography fontSize={12}>Change Logo</Typography>
            <input type="file" hidden onChange={onPickImage} accept="image/*" />
          </Button>
        </Box>
        <TextField
          autoFocus
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={newData.name}
          onChange={onChangeName}
          error={errorForm.name}
          helperText={errorForm.nameMessage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button
          onClick={onClickAdd}
          disabled={newData.name === '' || newData.logo === ''}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDashboardPaymentMethodAdd;
