import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosApi from '../utils/axiosApi';

function ResetPasswordCreate(props) {
  const { guid, token } = props;
  const navigate = useNavigate();
  const [dataPassword, setDataPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errorForm, setErrorForm] = useState({
    password: false,
    passwordMessage: '',
    confirmPassword: false,
    confirmPasswordMessage: '',
  });
  const [error, setError] = useState({ status: false, message: '' });
  const [success, setSuccess] = useState(null);

  const onChangePassword = (e) => {
    setDataPassword((prev) => ({ ...prev, password: e.target.value }));
    setErrorForm((prev) => ({ ...prev, password: false, passwordMessage: '' }));
    setError((prev) => ({ ...prev, status: false, message: '' }));
  };

  const onChangeConfirmPassword = (e) => {
    setDataPassword((prev) => ({ ...prev, confirmPassword: e.target.value }));
    setError((prev) => ({ ...prev, status: false, message: '' }));
    setErrorForm((prev) => ({
      ...prev,
      confirmPassword: false,
      confirmPasswordMessage: '',
    }));
  };

  const onClickConfirm = () => {
    if (dataPassword.password === '') {
      setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'New password must be filled',
      }));
    }
    if (dataPassword.confirmPassword === '') {
      setErrorForm((prev) => ({
        ...prev,
        confirmPassword: true,
        confirmPasswordMessage: 'Confirm new password must be filled',
      }));
    }
    if (dataPassword.password !== dataPassword.confirmPassword) {
      return setError((prev) => ({
        ...prev,
        status: true,
        message: 'Password and Confirm password not same',
      }));
    }

    const dataNewPassword = {
      guid,
      token,
      newPassword: dataPassword.password,
      newPasswordConfirmation: dataPassword.confirmPassword,
    };

    axiosApi
      .post('/api/reset-password', JSON.stringify(dataNewPassword))
      .then((res) => {
        if (res.data.status) {
          setSuccess(true);
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        } else {
          setError((prev) => ({
            ...prev,
            status: true,
            message: "we can't change your password. Please contact admin",
          }));
        }
      })
      .catch((err) => {
        setError((prev) => ({
          ...prev,
          status: true,
          message: 'Something wrong. Please contact admin.',
        }));
        console.log(err.response.data);
      });
  };

  return (
    <Box>
      <Box>
        <Typography color="secondary" fontWeight="bold" variant="h5">
          Create Password
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <TextField
          id="new-password"
          label="New Password"
          variant="outlined"
          sx={{ mt: 2 }}
          type="password"
          value={dataPassword.password}
          onChange={onChangePassword}
          error={errorForm.password}
          helperText={errorForm.passwordMessage}
        />
        <TextField
          id="confirm-new-password"
          label="Confirm New Password"
          variant="outlined"
          sx={{ mt: 2 }}
          type="password"
          value={dataPassword.confirmPassword}
          onChange={onChangeConfirmPassword}
          error={errorForm.confirmPassword}
          helperText={errorForm.confirmPasswordMessage}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onClickConfirm();
          }}
        />
      </Box>
      <Box mt={2}>
        {error.status === true && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )}
        {success === true && (
          <Alert severity="success">
            <AlertTitle>Success change password</AlertTitle>
            We will redirect you to login page in 3 seconds.
            <br />
            <Link to="/login">Click Here</Link> if not redirected
          </Alert>
        )}
      </Box>
      <Box
        display="flex"
        sx={{ justifyContent: { xs: 'space-between', md: 'end' } }}
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          disableElevation
          sx={{ mt: 2, px: 5, py: 1, borderRadius: 2, mr: 2 }}
          onClick={() => navigate('/')}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          sx={{ mt: 2, px: 5, py: 1, borderRadius: 2 }}
          onClick={onClickConfirm}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPasswordCreate;
