import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogPaymentMethod from '../components/DialogPaymentMethod';
import { formatCurrency } from '../utils';

import axiosApi from '../utils/axiosApi';
import TransactionContext from '../utils/TransactionContext';

function CheckoutBuyNow() {
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [dataCart, setDataCart] = useState('');
  const [isPay, setIsPay] = useState(false);

  const { setUpdateOnCart, currentCart, setUpdateOnClass, setUpdateOnInvoice } =
    useContext(TransactionContext);

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const fromLocal = localStorage.getItem('buynow');
    if (fromLocal) return setDataCart(JSON.parse(fromLocal));
    setDataCart(location.state);
  }, [location]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBuyFromBuyNow = () => {
    setIsPay(true);
    setTimeout(() => {
      const checkLocal = localStorage.getItem('buynow');
      if (checkLocal) localStorage.removeItem('buynow');
      const gettingAllCart = async () => {
        try {
          const filterCurrent = currentCart
            .filter((cart) => cart.fk_product_id === dataCart.id)
            .map((cur) => ({ ...cur, schedule: cur.schedule.slice(0, 10) }))
            .find(
              (cur) =>
                cur.schedule ===
                new Date(Date.parse(dataCart.schedule))
                  .toLocaleString('fr-CA', {
                    timeZone: 'Asia/Jakarta',
                  })
                  .slice(0, 10)
            );

          if (filterCurrent) {
            const res = await axiosApi.delete(
              `/api/cartproduct/${filterCurrent.guid}`
            );
            if (res) setUpdateOnCart(true);
          }
          const restPost = await axiosApi.post(`/api/order/buynow`, {
            fk_payment_method_id: selectedPayment,
            fk_product_id: dataCart.id,
            schedule: new Date(Date.parse(dataCart.schedule))
              .toLocaleString('fr-CA', {
                timeZone: 'Asia/Jakarta',
              })
              .slice(0, 10),
          });
          if (restPost.data) {
            setUpdateOnClass(true);
            setUpdateOnInvoice(true);
            navigate('/checkout/success');
          }
        } catch (error) {
          console.log(error);
        }
      };
      gettingAllCart();
    }, 1500);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
        <List
          sx={{ width: '100%', maxWidth: 'lg', bgcolor: 'background.paper' }}
        >
          <ListItem disablePadding>
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                checked={true}
                color="secondary"
                disableRipple
              />
            </ListItemIcon>
            <ListItemAvatar sx={{ py: 3, pr: 3, maxWidth: '200' }}>
              <Box
                component="img"
                sx={{ width: { xs: 100, sm: 150, md: 150, lg: 200 } }}
                alt={dataCart?.productName}
                src={dataCart?.productImage}
              />
            </ListItemAvatar>
            <ListItemText sx={{ gap: 1 }}>
              <Typography
                variant="subtitle1"
                color="gray3"
                sx={{
                  lineHeight: '20px',
                  fontWeight: '400',
                  mb: 0.5,
                }}
              >
                {dataCart?.categoryName}
              </Typography>
              <Typography
                variant="h5"
                color="#333"
                sx={{
                  lineHeight: '29px',
                  fontWeight: '600',
                  mb: 1,
                }}
              >
                {dataCart?.productName}
              </Typography>
              <Typography
                variant="subtitle1"
                color="gray2"
                sx={{
                  lineHeight: '20px',
                  fontWeight: '400',
                  mb: 1,
                }}
              >
                {dataCart?.schedule}
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  lineHeight: '24px',
                  fontWeight: '600',
                }}
              >
                {formatCurrency(dataCart?.productPrice)}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Container>

      <AppBar position="fixed" color="white" sx={{ top: 'auto', bottom: 0 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Typography
              variant="subtitle1"
              color="gray1"
              sx={{
                lineHeight: '22px',
                fontWeight: '400',
                mr: 3,
              }}
            >
              Total Price
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                lineHeight: '29px',
                fontWeight: '600',
              }}
            >
              {formatCurrency(dataCart?.productPrice)}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="primary"
              sx={{ px: 7, textTransform: 'none' }}
              dense="true"
              disableElevation
              onClick={handleClickOpen}
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{
                  lineHeight: '20px',
                  fontWeight: '500',
                }}
              >
                Pay Now
              </Typography>
            </Button>
          </Toolbar>
        </Container>
      </AppBar>

      <DialogPaymentMethod
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        open={open}
        setOpen={setOpen}
        onclick={handleBuyFromBuyNow}
        isPay={isPay}
      />
    </Box>
  );
}

export default CheckoutBuyNow;
