import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeIcon from '@mui/icons-material/Home';
import {
  Box, Button, Typography
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function SuccessOrder() {
  const navigate = useNavigate()

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center">
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Box component="img" src="../assets/success.svg" height={200} />
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography
            variant='h5'
            color='gray1' sx={{
              lineHeight: '29px',
              fontWeight: '500',
              mb: 1
            }}>Purchase Successfully</Typography>
          <Typography
            variant='body1' sx={{
              lineHeight: '29px',
              fontWeight: '400',
            }}>Horay!! Let’s cook and become a professional cheff</Typography>
          <Box display='flex' justifyContent='space-between' mt={5} gap={3}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              disableElevation
              startIcon={<HomeIcon />}
              sx={{ py: 2, px: 3, textTransform: 'none' }}
              onClick={() => navigate('/')}
            >
              <Typography variant="subtitle1" fontWeight='600'>Back to Home</Typography>
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              startIcon={<ArrowForwardIcon />}
              sx={{ py: 2, px: 3, textTransform: 'none' }}
              onClick={() => navigate('/invoice')}
            >
              <Typography variant="subtitle1" fontWeight='600'>Open Invoice</Typography>
            </Button>

          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SuccessOrder;
