import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import EditIcon from '@mui/icons-material/Edit';
import axiosApi from '../../utils/axiosApi';
import _ from 'lodash';

function DashboardProfile() {
  const [editNameDialog, setEditNameDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [dataUser, setDataUser] = useState({ name: '', email: '', role: '' });
  const [editedName, setEditedName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [errorForm, setErrorForm] = useState({
    name: false,
    nameMessage: '',
    currentPassword: false,
    currentPasswordMessage: '',
    newPassword: false,
    newPasswordMessage: '',
    newConfirmPassword: false,
    newConfirmPasswordMessage: '',
  });
  const [editedPassword, setEditedPassword] = useState({
    currentPassword: '',
    newPassword: '',
    newConfirmPassword: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    status: 'success',
    message: '',
  });

  useEffect(() => {
    document.title = 'Soup Project | Dashboard Profile';
    axiosApi
      .get('api/user')
      .then((res) => {
        setDataUser((prev) => ({
          ...prev,
          name: res.data.name,
          email: res.data.email,
          role: res.data.role_name,
        }));
        _.delay(() => setFirstLoading(false), 1000);
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    if (snackbar.open === true) {
      axiosApi
        .get('api/user')
        .then((res) => {
          setDataUser((prev) => ({
            ...prev,
            name: res.data.name,
            email: res.data.email,
            role: res.data.role_name,
          }));
        })
        .catch((err) => console.log(err.response.data));
    }
  }, [snackbar.open]);

  const handleOpenEditNameDialog = () => {
    setEditNameDialog(true);
  };

  const handleCloseEditNameDialog = () => {
    setEditNameDialog(false);
  };

  const handleOpenChangePasswordDialog = () => {
    setChangePasswordDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setChangePasswordDialog(false);
  };

  const handleOnChangeName = (e) => {
    setErrorForm((prev) => ({ ...prev, name: false, nameMessage: '' }));
    setEditedName(e.target.value);
  };

  const handleOnChangeCurrentPassword = (e) => {
    setErrorForm((prev) => ({
      ...prev,
      currentPassword: false,
      currentPasswordMessage: '',
    }));
    setEditedPassword((prev) => ({
      ...prev,
      currentPassword: e.target.value,
    }));
  };

  const handleOnChangeNewPassword = (e) => {
    setErrorForm((prev) => ({
      ...prev,
      newPassword: false,
      newPasswordMessage: '',
    }));
    setEditedPassword((prev) => ({
      ...prev,
      newPassword: e.target.value,
    }));
  };

  const handleOnChangeNewConfirmPassword = (e) => {
    setErrorForm((prev) => ({
      ...prev,
      newConfirmPassword: false,
      newConfirmPasswordMessage: '',
    }));
    setEditedPassword((prev) => ({
      ...prev,
      newConfirmPassword: e.target.value,
    }));
  };

  const handleClickChange = (type) => {
    let data = {};
    if (type === 'name') {
      if (editedName === '') {
        return setErrorForm((prev) => ({
          ...prev,
          name: true,
          nameMessage: "Name can't empty",
        }));
      }
      data = { name: editedName };
      setEditedName('');
    } else if (type === 'password') {
      if (editedPassword.currentPassword === '') {
        setErrorForm((prev) => ({
          ...prev,
          currentPassword: true,
          currentPasswordMessage: 'Curent Password cannot empty',
        }));
      }
      if (editedPassword.newPassword === '') {
        setErrorForm((prev) => ({
          ...prev,
          newPassword: true,
          newPasswordMessage: 'New Password cannot empty',
        }));
      }
      if (editedPassword.newConfirmPassword === '') {
        setErrorForm((prev) => ({
          ...prev,
          newConfirmPassword: true,
          newConfirmPasswordMessage: 'New Confirm Password cannot empty',
        }));
      }
      if (
        editedPassword.currentPassword === '' ||
        editedPassword.newPassword === '' ||
        editedPassword.newConfirmPassword === ''
      ) {
        return;
      }
      if (editedPassword.newPassword !== editedPassword.newConfirmPassword) {
        return setErrorForm((prev) => ({
          ...prev,
          newConfirmPassword: true,
          newConfirmPasswordMessage:
            'Confirm password not same as new password',
        }));
      }
      data = {
        password: editedPassword.currentPassword,
        newPassword: editedPassword.newPassword,
      };
      setEditedPassword({
        currentPassword: '',
        newPassword: '',
        newConfirmPassword: '',
      });
    }
    setIsLoading(true);
    axiosApi
      .put('api/user', data)
      .then((res) => {
        if (res.data.name === true) {
          handleCloseEditNameDialog();
          setSnackbar((prev) => ({
            ...prev,
            open: true,
            status: 'success',
            message: 'Success change name',
          }));
          setIsLoading(false);
        } else if (res.data.password === true) {
          handleCloseChangePasswordDialog();
          setSnackbar((prev) => ({
            ...prev,
            open: true,
            status: 'success',
            message: 'Success change password',
          }));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        handleCloseEditNameDialog();
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'error',
          message: err.response.data.message,
        }));
        setIsLoading(false);
        console.log(err.response.data);
      });
  };

  const handleOpenCloseSnackbar = (type) => {
    type === 'open' && setSnackbar((prev) => ({ ...prev, open: true }));
    type === 'close' && setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const MergedList = ({ onClickEnd, iconEnd, icon, primary, secondary }) => {
    return (
      <ListItem
        secondaryAction={
          <IconButton edge="end" onClick={onClickEnd}>
            {iconEnd}
          </IconButton>
        }
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  };

  return (
    <>
      <Paper>
        <Box px={3} py={4}>
          <Typography align="center" variant="h5">
            Profile
          </Typography>
          <List>
            {firstLoading && (
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
            )}
            {!firstLoading && (
              <MergedList
                onClickEnd={handleOpenEditNameDialog}
                iconEnd={<EditIcon />}
                icon={<PersonIcon />}
                primary="Name"
                secondary={dataUser.name}
              />
            )}
            <Divider />
            {firstLoading && (
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
            )}
            {!firstLoading && (
              <MergedList
                icon={<EmailIcon />}
                primary="Email"
                secondary={dataUser.email}
              />
            )}
            <Divider />
            {firstLoading && (
              <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
            )}
            {!firstLoading && (
              <MergedList
                icon={<LockPersonIcon />}
                primary="Role"
                secondary={dataUser.role}
              />
            )}
          </List>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              disableElevation
              color="primary"
              sx={{ px: 3, textTransform: 'none' }}
              onClick={handleOpenChangePasswordDialog}
            >
              Change Password
            </Button>
          </Box>
        </Box>
      </Paper>
      {!dataUser.name && <CircularProgress />}
      <Dialog open={editNameDialog} onClose={handleCloseEditNameDialog}>
        <Box p={1}>
          <DialogTitle>Change name</DialogTitle>
          <DialogContent>
            <DialogContentText>You can change your name here</DialogContentText>
            <TextField
              autoFocus
              id="name"
              label="Name"
              fullWidth
              variant="standard"
              value={editedName}
              onChange={handleOnChangeName}
              error={errorForm.name}
              helperText={errorForm.nameMessage}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleClickChange('name');
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditNameDialog}>Cancel</Button>
            <Button onClick={() => handleClickChange('name')}>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                'Change'
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={changePasswordDialog}
        onClose={handleCloseChangePasswordDialog}
      >
        <Box p={1}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your current password and new password
            </DialogContentText>
            <TextField
              id="current-password"
              label="Current Password"
              variant="standard"
              sx={{ mt: 2 }}
              type="password"
              fullWidth
              value={editedPassword.currentPassword}
              onChange={handleOnChangeCurrentPassword}
              error={errorForm.currentPassword}
              helperText={errorForm.currentPasswordMessage}
            />
            <TextField
              id="new-password"
              label="New Password"
              variant="standard"
              sx={{ mt: 2 }}
              type="password"
              fullWidth
              value={editedPassword.newPassword}
              onChange={handleOnChangeNewPassword}
              error={errorForm.newPassword}
              helperText={errorForm.newPasswordMessage}
            />
            <TextField
              id="confirm-new-password"
              label="Confirm New Password"
              variant="standard"
              sx={{ mt: 2 }}
              type="password"
              fullWidth
              value={editedPassword.newConfirmPassword}
              onChange={handleOnChangeNewConfirmPassword}
              error={errorForm.newConfirmPassword}
              helperText={errorForm.newConfirmPasswordMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseChangePasswordDialog}>Cancel</Button>
            <Button onClick={() => handleClickChange('password')}>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                'Change'
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => handleOpenCloseSnackbar('close')}
          severity={snackbar.status}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DashboardProfile;
