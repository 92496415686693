import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../utils/UserContext';
import axiosApi from '../../utils/axiosApi';

import jwt_decode from 'jwt-decode';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';

function DashboardCategory() {
  const [userData, setuserData] = useState('');
  const { currentUser } = useContext(UserContext);
  const [categoryData, setCategoryData] = useState([]);
  const [getCategoryData, setGetCategoryData] = useState(true);
  const token = currentUser ? jwt_decode(currentUser) : {};
  const [open, setOpen] = useState(false);
  const [newData, setNewData] = useState(false);
  const [toChangeData, setToChangeData] = useState({
    id: 0,
    categoryName: '',
    categoryDetail: '',
    categoryImage: '',
    categoryBanner: '',
  });

  useEffect(() => {
    setuserData(token.role);
  }, [token.role]);

  useEffect(() => {
    if (!getCategoryData) return;
    const getCategory = async () => {
      try {
        const res = await axiosApi.get('/api/category');
        const data = res.data;
        setCategoryData(
          data?.map((data) => {
            return {
              id: data.id,
              categoryName: data.category_name,
              categoryDetail: data.category_detail,
              categoryImage: data.category_image,
              categoryBanner: data.category_banner,
            };
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        setGetCategoryData(false);
      }
    };
    getCategory();
  }, [getCategoryData]);

  const handleChangingName = (e) => {
    setToChangeData((prev) => {
      return { ...prev, categoryName: e.target.value };
    });
  };

  const handleChangingDetail = (e) => {
    setToChangeData((prev) => {
      return { ...prev, categoryDetail: e.target.value };
    });
  };

  const handleChangingImage = (e) => {
    const tempImage = e.target.files[0];
    console.log('masuk');

    let reader = new FileReader();

    reader.onload = function () {
      setToChangeData((prev) => {
        return { ...prev, categoryImage: reader.result };
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

    reader.readAsDataURL(tempImage);
  };

  const handleChangeBanner = (e) => {
    const tempImage = e.target.files[0];

    let reader = new FileReader();

    reader.onload = function () {
      setToChangeData((prev) => {
        return { ...prev, categoryBanner: reader.result };
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

    reader.readAsDataURL(tempImage);
  };

  const submitChange = async () => {
    const data = {
      id: toChangeData.id,
      category_name: toChangeData.categoryName,
      category_detail: toChangeData.categoryDetail,
      category_image: toChangeData.categoryImage,
      category_banner: toChangeData.categoryBanner,
    };
    try {
      const res = await axiosApi.put('/api/category', data);
      if (res) setGetCategoryData(true);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const submitNewProduct = async () => {
    const dataToBack = {
      category_name: toChangeData.categoryName,
      category_detail: toChangeData.categoryDetail,
      category_image: toChangeData.categoryImage,
      category_banner: toChangeData.categoryBanner,
    };
    try {
      const res = await axiosApi.post('/api/category', dataToBack);
      if (res) setGetCategoryData(true);
    } catch (error) {
      console.log(error);
    } finally {
      setNewData(false);
      setOpen(false);
    }
  };

  const toSubmit = () => {
    if (newData) return submitNewProduct();
    return submitChange();
  };

  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 1,
          }}
        >
          <Typography variant="h5">My Course</Typography>
          <Button
            variant="contained"
            onClick={(e) => {
              setOpen(true);
              setNewData(true);
              setToChangeData(toChangeData);
            }}
            sx={{ display: userData === 'admin' ? 'inline-flex' : 'none' }}
          >
            New Course
          </Button>
        </Box>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell>Category Detail</TableCell>
              <TableCell>Category Image</TableCell>
              <TableCell>Category Banner</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          {!getCategoryData && (
            <TableBody>
              {categoryData.map((category, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    {category.categoryName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextareaAutosize
                      readOnly
                      maxRows={8}
                      value={category.categoryDetail}
                      style={{ width: 250 }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box
                        component={'img'}
                        width={100}
                        src={category.categoryImage}
                        mr={2}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box
                        component={'img'}
                        width={100}
                        src={category.categoryBanner}
                        mr={2}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        setOpen(true);
                        setToChangeData(category);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          <TableBody>
            {getCategoryData &&
              Array.from({ length: 6 }, (_, i) => i + 1).map(
                (category, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </Paper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="cateogory_name"
              name="cateogory_name"
              label="Category Name"
              fullWidth
              variant="standard"
              value={toChangeData.categoryName}
              onChange={handleChangingName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="cateogory_detail"
              name="cateogory_detail"
              label="Category Detail"
              fullWidth
              variant="standard"
              value={toChangeData.categoryDetail}
              onChange={handleChangingDetail}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Button variant="contained" component="label">
              Upload Image
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleChangingImage}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Button variant="contained" component="label">
              Upload Banner
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleChangeBanner}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button variant="contained" onClick={toSubmit}>
              SUBMIT
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
          >
            <Typography>Preview Image : </Typography>
            <Box
              component={'img'}
              width={100}
              src={toChangeData.categoryImage}
              mr={2}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
            <Typography>Preview Banner : </Typography>
            <Box
              component={'img'}
              width={100}
              src={toChangeData.categoryBanner}
              mr={2}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default DashboardCategory;
