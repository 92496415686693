import { Card, CardContent, CardMedia, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosApi from '../../utils/axiosApi';
import UserContext from '../../utils/UserContext';
import jwt_decode from 'jwt-decode';

function Dashboard() {
  const [ownedCourse, setOwnedCourse] = useState(0);
  const [role, setRole] = useState('');

  const { currentUser } = useContext(UserContext);
  const token = currentUser ? jwt_decode(currentUser) : {};
  useEffect(() => {
    setRole(token.role);
  }, [token.role]);

  useEffect(() => {
    if (!role) return;
    const getCourseByRole = async () => {
      try {
        if (role === 'admin') {
          const getCourseAdminRes = await axiosApi.get('/api/product');
          const dataForAdmin = getCourseAdminRes.data;
          setOwnedCourse(dataForAdmin.length);
        } else {
          const getCourseAdminRes = await axiosApi.get('api/myclass/user');
          const dataForAdmin = getCourseAdminRes.data;
          setOwnedCourse(dataForAdmin.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCourseByRole();
  }, [role]);
  return (
    <Paper sx={{ p: 2, width: 'fit-content' }}>
      <Card sx={{ display: 'flex', width: 'fit-content', gap: '30px' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            My Class
          </Typography>
          <Typography variant="h5" color="primary.main" fontWeight={500}>
            {ownedCourse}
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          sx={{ width: '300px' }}
          image="../assets/list_class.svg"
          alt="List Class"
        />
      </Card>
    </Paper>
  );
}

export default Dashboard;
