import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Fade from '@mui/material/Fade';

import { formatDate } from '../utils';
import { Typography } from '@mui/material';

export default function SelectionCourseDetail({ date, setDate }) {
  let currentDate = new Date();
  const dateData = Array.from({ length: 7 }, (_, i) =>
    currentDate.setDate(
      i === 0 ? currentDate.getDate() : currentDate.getDate() + 1
    )
  );

  const handleChange = (event) => setDate(event.target.value);

  return (
    <Box sx={{ width: { xs: '100%', lg: '300px' } }}>
      <Fade in={!date} color="primary.main">
        <Typography variant="subtitle2">Please select your schedule</Typography>
      </Fade>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Schedule</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={date}
          label="Select Schedule"
          onChange={handleChange}
        >
          {dateData.map((date) => (
            <MenuItem key={date} value={formatDate(date)}>
              {formatDate(date)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
