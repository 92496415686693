import { Box, Container, Typography, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LinkMaterial from '@mui/material/Link';
import axiosApi from '../../utils/axiosApi';

function Footer() {
  const [listCategory, setlistCategory] = useState([]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await axiosApi.get('/api/category');
        const data = res.data;
        setlistCategory(
          data?.map((data) => {
            return {
              id: data.id,
              categoryName: data.category_name,
              slug: data.slug,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    getCategory();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: 'secondary.main',
        width: '1',
      }}
      py={3}
      mt={10}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'space-between',
            gap: { xs: 4, lg: 2 },
            height: 'auto',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box flex={1}>
            <Typography
              variant="h6"
              color="primary.main"
              fontWeight={500}
              mb={2}
            >
              About Us
            </Typography>
            <Typography color="white.main" variant="body">
              Lorem eu fugiat sit ea commodo id non labore aliquip
              reprehenderit.Commodo enim minim ad labore pariatur sunt commodo
              et.
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography
              variant="h6"
              color="primary.main"
              fontWeight={500}
              mb={2}
            >
              Courses List
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {listCategory?.slice(0, 8).map((category) => (
                <Box
                  sx={{ flex: '45%', textDecoration: 'none' }}
                  component={Link}
                  key={category.id}
                  to={`/category/${category.slug}`}
                >
                  <Typography
                    color="white.main"
                    variant="body"
                    sx={{ textDecoration: 'none' }}
                  >
                    {category.categoryName}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            flex={1}
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={1}
          >
            <Box>
              <Typography
                variant="h6"
                color="primary.main"
                fontWeight={500}
                gutterBottom
              >
                Address
              </Typography>
              <Typography color="white.main" variant="body">
                Lorem eu fugiat sit ea commodo id non labore aliquip
                reprehenderit.Commodo enim minim ad labore pariatur sunt commodo
                et.
              </Typography>
            </Box>
            <Box width={1}>
              <Typography
                variant="h6"
                color="primary.main"
                fontWeight={500}
                gutterBottom
              >
                Contact Us
              </Typography>
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: 'left', lg: 'space-between' }}
                gap={{ xs: 2, sm: 4 }}
                mt={1}
              >
                <IconButton
                  component={LinkMaterial}
                  sx={{ padding: 0 }}
                  target="_blank"
                  href="/"
                >
                  <LocalPhoneIcon
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: 100,
                      padding: 0.5,
                      fontSize: '32px',
                    }}
                  />
                </IconButton>
                <IconButton
                  component={LinkMaterial}
                  target="_blank"
                  href="/"
                  sx={{ padding: 0 }}
                >
                  <YouTubeIcon
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: 100,
                      padding: 0.5,
                      fontSize: '32px',
                    }}
                  />
                </IconButton>
                <IconButton
                  component={LinkMaterial}
                  target="_blank"
                  href="/"
                  sx={{ padding: 0 }}
                >
                  <TelegramIcon
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: 100,
                      padding: 0.5,
                      fontSize: '32px',
                    }}
                  />
                </IconButton>
                <IconButton
                  component={LinkMaterial}
                  target="_blank"
                  href="/"
                  sx={{ padding: 0 }}
                >
                  <InstagramIcon
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: 100,
                      padding: 0.5,
                      fontSize: '32px',
                    }}
                  />
                </IconButton>
                <IconButton
                  component={LinkMaterial}
                  target="_blank"
                  href="/"
                  sx={{ padding: 0 }}
                >
                  <EmailOutlinedIcon
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: 100,
                      padding: 0.5,
                      fontSize: '32px',
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
