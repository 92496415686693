import {
  AppBar,
  Box,
  Button,
  Container,
  ThemeProvider,
  Toolbar,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import DialogPaymentMethod from '../components/DialogPaymentMethod';
import ListCheckout from '../components/ListCheckout';
import EmptyList from '../components/reusable/EmptyList';
import { formatCurrency } from '../utils';
import TransactionContext from '../utils/TransactionContext';
import axiosApi from '../utils/axiosApi';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';

function Checkout() {
  const theme = useTheme();
  theme.typography.h5 = {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  };

  theme.typography.h6 = {
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  };

  theme.typography.subtitle1 = {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [checked, setChecked] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPay, setIsPay] = useState(false);

  const {
    currentCart,
    setCurrentCart,
    setUpdateOnCart,
    updateOnCart,
    setUpdateOnClass,
    setUpdateOnInvoice,
  } = useContext(TransactionContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    document.title = 'Soup Project | Checkout'
    handleTotalPrice()

  }, [totalPrice, checked])

  const handleTotalPrice = () => {
    const checkedData = checked.map((id) => {
      return currentCart.find((e) => e.guid === id);
    });

    let total = 0;
    checkedData.map((e) => {
      return (total += Number(e.product_price));
    });

    return setTotalPrice(total);
  };

  const handleCheckout = () => {
    setIsPay(true);

    setTimeout(() => {
      // ambil data dari checked terus di validasi ke cart
      const getDataChecked = checked.map((id) => {
        return currentCart.find((e) => e.guid === id);
      });

      // get list product id yang mau dicheckout
      const listProductChecked = [];
      getDataChecked.map((e) => {
        return listProductChecked.push(e.guid);
      });

      const postCheckout = async () => {
        try {
          const resPostCheckout = await axiosApi.post(`/api/order/checkout`, {
            fk_payment_method_id: selectedPayment,
            cart_product: listProductChecked,
          });
          if (resPostCheckout) {
            setUpdateOnCart(true);
            setUpdateOnClass(true);
            setUpdateOnInvoice(true);
            setIsPay(false);

            navigate('/checkout/success');
          }
        } catch (error) {
          console.log(error);
        }
      };

      postCheckout();
      // console.log('abis nunggu 1,5 detik')
    }, 1500);
  };

  const sortedCart = sortBy(currentCart, 'created_at').reverse();
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
      >
        {sortedCart.length === 0 && (
          <Container
            maxWidth="lg"
            height={1}
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <Box
              maxWidth="lg"
              sx={{ flexGrow: 1 }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <EmptyList />
              </Box>
            </Box>
          </Container>
        )}

        {sortedCart.length > 0 && (
          <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
            <ListCheckout
              checked={checked}
              setChecked={setChecked}
              data={sortedCart}
              setData={setCurrentCart}
            />
          </Container>
        )}

        <AppBar position="fixed" color="white" sx={{ top: 'auto', bottom: 0 }}>
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Typography
                variant="subtitle1"
                color="gray1"
                sx={{
                  lineHeight: '22px',
                  fontWeight: '400',
                  mr: 3,
                }}
              >
                Total Price
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  lineHeight: '29px',
                  fontWeight: '600',
                }}
              >
                {checked.length < 1
                  ? formatCurrency(0)
                  : formatCurrency(totalPrice)}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                variant="contained"
                color="primary"
                sx={{ px: 7, textTransform: 'none' }}
                dense="true"
                disableElevation
                disabled={checked.length < 1}
                onClick={handleClickOpen}
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  sx={{
                    lineHeight: '20px',
                    fontWeight: '500',
                  }}
                >
                  Pay Now
                </Typography>
              </Button>
            </Toolbar>
          </Container>
        </AppBar>

        <DialogPaymentMethod
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
          open={open}
          setOpen={setOpen}
          onclick={handleCheckout}
          isPay={isPay}
        />
      </Box>
    </ThemeProvider>
  );
}

export default Checkout;
