import React, { useState } from 'react';
import { Grid, Button, Skeleton, Box, Stack } from '@mui/material';

// Component
import CardLandingPage from '../CardLandingPage';
import EmptyList from './EmptyList';

const CoursesList = ({
  dataCourses,
  landingPage,
  courseDetail,
  gettingCourse,
}) => {
  let intialData = 6;
  const [loadMore, setLoadMore] = useState(intialData);
  if (!dataCourses) return;
  const dataDisplayed = dataCourses
    ?.slice()
    ?.filter((data) => data.id !== courseDetail?.id);

  const handleLoadMore = () => setLoadMore((prev) => prev + intialData);

  return (
    <>
      {gettingCourse && (
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
          my={{ xs: 5, lg: 10 }}
        >
          {Array.from({ length: 6 }, (_, i) => i + 1).map((data) => (
            <Stack key={data} spacing={1}>
              <Skeleton
                variant="rectangular"
                height={233}
                sx={{
                  borderRadius: '20px',
                  width: { xs: 280, lg: 350 },
                }}
              />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </Stack>
          ))}
        </Box>
      )}
      <Grid container spacing={{ xs: 2, lg: 4 }} px={{ xs: 2, lg: 0 }} my={10}>
        {!gettingCourse && dataCourses.length === 0 && <EmptyList />}
        {dataCourses &&
          dataDisplayed
            ?.slice(0, loadMore)
            .map((course, i) => (
              <CardLandingPage
                key={i}
                catergoryName={course.categoryName}
                productName={course.productName}
                imageSource={course.productImage}
                productPrice={course.productPrice}
                slug={course.slug}
                dataCourses={dataCourses}
              />
            ))}
        <Grid
          item
          xs={12}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
          display={
            loadMore >= dataCourses.length || landingPage ? 'none' : 'flex'
          }
        >
          <Button onClick={handleLoadMore}>Load More</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CoursesList;
