import React, { useContext, useEffect, useState } from 'react';
import {
  Divider,
  Paper,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Button,
  Stack,
  Skeleton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axiosApi from '../../utils/axiosApi';
import { PhotoCamera } from '@mui/icons-material';
import EmptyList from '../../components/reusable/EmptyList';
import UserContext from '../../utils/UserContext';
import jwt_decode from 'jwt-decode';

function DashboardCourse() {
  const [userData, setuserData] = useState('');
  const [courseData, setCourseData] = useState([]);
  const [getCoursdata, setGetCoursdata] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [newData, setNewData] = useState(false);
  const [toChangeData, setToChangeData] = useState({
    id: 0,
    productName: '',
    productImage: '',
    productDetail: '',
    productPrice: 0,
    categoryId: '',
    checked: true,
    active: true,
  });

  const { currentUser } = useContext(UserContext);
  const token = currentUser ? jwt_decode(currentUser) : {};

  const handleChangingName = (e) => {
    setToChangeData((prev) => {
      return { ...prev, productName: e.target.value };
    });
  };

  const handleChangingProductDetail = (e) => {
    setToChangeData((prev) => {
      return { ...prev, productDetail: e.target.value };
    });
  };

  const handleChangingPrice = (e) => {
    setToChangeData((prev) => {
      return { ...prev, productPrice: e.target.value };
    });
  };

  const handleChangingCategory = (e) => {
    setToChangeData((prev) => {
      return { ...prev, categoryId: e.target.value };
    });
  };

  const handleChangingActive = (e) => {
    setToChangeData((prev) => {
      return { ...prev, active: e.target.checked };
    });
  };

  const handleChangingImage = (e) => {
    const tempImage = e.target.files[0];

    let reader = new FileReader();

    reader.onload = function () {
      setToChangeData((prev) => {
        return { ...prev, productImage: reader.result };
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

    reader.readAsDataURL(tempImage);
  };

  useEffect(() => {
    setuserData(token.role);
  }, [token.role]);

  useEffect(() => {
    if (!getCoursdata || !userData) return;
    const getCourseByRole = async () => {
      try {
        if (userData === 'admin') {
          const getCourseAdminRes = await axiosApi.get('/api/product');
          const dataForAdmin = getCourseAdminRes.data;
          setCourseData(
            dataForAdmin?.map((data) => {
              return {
                id: data.id,
                productName: data.product_name,
                productImage: data.product_image,
                productDetail: data.product_detail,
                productPrice: data.product_price,
                categoryId: data.fk_category_id,
                categoryName: data.category_name,
                active: data.active,
              };
            })
          );
        } else {
          const getCourseAdminRes = await axiosApi.get('api/myclass/user');
          const dataForAdmin = getCourseAdminRes.data;
          setCourseData(
            dataForAdmin?.map((data) => {
              return {
                id: data.id,
                productName: data.product_name,
                productImage: data.product_image,
                productDetail: data.product_detail,
                productPrice: data.product_price,
                categoryId: data.fk_category_id,
                categoryName: data.category_name,
                active: data.active,
              };
            })
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setGetCoursdata(false);
      }
    };
    getCourseByRole();
  }, [userData, getCoursdata]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await axiosApi.get('/api/category');
        const data = res.data;
        setCategoryData(
          data?.map((data) => {
            return {
              id: data.id,
              categoryName: data.category_name,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    getCategory();
  }, []);

  const submitChange = async () => {
    const data = {
      id: toChangeData.id,
      product_name: toChangeData.productName,
      product_detail: toChangeData.productDetail,
      product_image: toChangeData.productImage,
      product_price: toChangeData.productPrice,
      fk_category_id: toChangeData.categoryId,
      active: toChangeData.active,
    };
    try {
      const res = await axiosApi.put('/api/Product', data);
      if (res) setGetCoursdata(true);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const submitNewProduct = async () => {
    const data = {
      id: toChangeData.id,
      product_name: toChangeData.productName,
      product_detail: toChangeData.productDetail,
      product_image: toChangeData.productImage,
      product_price: toChangeData.productPrice,
      fk_category_id: toChangeData.categoryId,
    };
    try {
      const res = await axiosApi.post('/api/Product', data);
      if (res) setGetCoursdata(true);
    } catch (error) {
      console.log(error);
    } finally {
      setNewData(false);
      setOpen(false);
    }
  };

  const toSubmit = () => {
    if (newData) return submitNewProduct();
    return submitChange();
  };
  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 1,
          }}
        >
          <Typography variant="h5">My Course</Typography>
          <Button
            variant="contained"
            onClick={(e) => {
              setOpen(true);
              setNewData(true);
              setToChangeData(toChangeData);
            }}
            sx={{ display: userData === 'admin' ? 'inline-flex' : 'none' }}
          >
            New Course
          </Button>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '30px',
            p: 2,
            justifyContent: 'center',
          }}
        >
          {getCoursdata && (
            <Box
              sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}
              my={10}
            >
              {Array.from({ length: 6 }, (_, i) => i + 1).map((data) => (
                <Stack key={data} spacing={1}>
                  <Skeleton
                    variant="rectangular"
                    width={275}
                    height={233}
                    sx={{ borderRadius: '20px' }}
                  />
                </Stack>
              ))}
            </Box>
          )}
          {courseData.length === 0 && !getCoursdata && <EmptyList />}
          {courseData.length !== 0 &&
            courseData?.map((course, i) => (
              <Card sx={{ width: 275, aspectRatio: '1 / 1' }} key={i}>
                <CardMedia
                  component="img"
                  height="140"
                  image={course.productImage}
                  alt="green iguana"
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 2,
                    height: '50%',
                  }}
                >
                  <CardContent sx={{ m: 0, p: 0 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      textAlign={'center'}
                    >
                      {course.productName}
                    </Typography>
                  </CardContent>
                  {userData === 'admin' && (
                    <CardActions sx={{ m: 0, py: 0 }}>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          setOpen(true);
                          setToChangeData(course);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </CardActions>
                  )}
                </Box>
              </Card>
            ))}
        </Box>
      </Paper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="product_name"
              name="product_name"
              label="Product Name"
              fullWidth
              variant="standard"
              value={toChangeData.productName}
              onChange={handleChangingName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="product_detail"
              name="product_detail"
              label="Product Detail"
              fullWidth
              variant="standard"
              value={toChangeData.productDetail}
              onChange={handleChangingProductDetail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="product_price"
              name="product_price"
              label="Price"
              fullWidth
              type="number"
              value={toChangeData.productPrice}
              onChange={handleChangingPrice}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="category"
              fullWidth
              value={toChangeData.categoryId}
              onChange={handleChangingCategory}
            >
              {categoryData?.map((data) => (
                <MenuItem value={data.id} key={data.id}>
                  {data.categoryName}
                </MenuItem>
              ))}

              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              checked={toChangeData.active}
              onChange={handleChangingActive}
              label="Active"
              control={<Checkbox />}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Button variant="contained" component="label">
              Upload
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleChangingImage}
              />
            </Button>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" />
              <PhotoCamera />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button variant="contained" onClick={toSubmit}>
              SUBMIT
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
          >
            <Typography>Preview Image : </Typography>
            <Box
              component={'img'}
              width={100}
              src={toChangeData.productImage}
              mr={2}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default DashboardCourse;
