import React, { useEffect, useState } from 'react';
import { Box, Container, Paper, Typography, Divider } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CoursesList from '../components/reusable/CoursesList';
import Readmore from '../components/Readmore';
import _ from 'lodash';

import axiosApi from '../utils/axiosApi';

function CategoryDetail() {
  const [singleCategory, setSingleCategory] = useState({});

  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getSingleCategory = async () => {
      try {
        const res = await axiosApi.get(`/api/category/${params.name}`);
        const data = res.data;
        data.listProduct.map((el) =>
          _.mapKeys(el, (val, key) => _.camelCase(key))
        );

        if (data?.id === 0) throw new Error();

        setSingleCategory({
          id: data.id,
          categoryName: data.category_name,
          categoryImage: data.category_image,
          slug: data.slug,
          categoryBanner: data.category_banner,
          categoryDetail: data.category_detail,
          listProduct: data.listProduct.map((el) =>
            _.mapKeys(el, (val, key) => _.camelCase(key))
          ),
        });
      } catch (error) {
        if (error) navigate('/nowhere');
      }
    };
    getSingleCategory();
  }, [navigate, params]);

  useEffect(() => {
    if (!singleCategory.id) return;
    document.title = `Soup Project | Course Detail : ${singleCategory.categoryName}`;
  }, [singleCategory]);

  return (
    <Box
      display="flex"
      minHeight={1}
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
    >
      <Paper
        sx={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          backgroundImage: `url(${singleCategory.categoryBanner})`,
          height: '300px',
          mb: 8,
        }}
      />
      <Container maxWidth="lg">
        <Box mb={10}>
          <Typography variant="h5" gutterBottom fontWeight={'600'}>
            {singleCategory.categoryName}
          </Typography>
          <Readmore detailText={singleCategory.categoryDetail} />
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="lg">
        <Box my={12}>
          <Typography
            variant="h3"
            gutterBottom
            fontWeight={600}
            align="center"
            mb={10}
          >
            {singleCategory.categoryName}
          </Typography>
          <CoursesList dataCourses={singleCategory?.listProduct} />
        </Box>
      </Container>
    </Box>
  );
}

export default CategoryDetail;
