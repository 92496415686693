import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import _ from 'lodash';
import axiosApi from '../utils/axiosApi';

function DialogDashboardAddUser({
  openDialog,
  setOpenDialog,
  allRole,
  setSnackbar,
  refresh,
}) {
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    role_id: 1,
    verified: false,
    active: false,
  });
  const [errorForm, setErrorForm] = useState({
    name: false,
    nameMessage: '',
    email: false,
    EmailMessage: '',
    password: false,
    passwordMessage: '',
  });

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onChangeName = (e) => {
    setData((prev) => ({ ...prev, name: e.target.value }));
    setErrorForm((prev) => ({ ...prev, name: false, nameMessage: '' }));
  };

  const onChangeEmail = (e) => {
    setData((prev) => ({ ...prev, email: e.target.value }));
    setErrorForm((prev) => ({ ...prev, email: false, EmailMessage: '' }));
  };

  const onChangePassword = (e) => {
    setData((prev) => ({ ...prev, password: e.target.value }));
    setErrorForm((prev) => ({ ...prev, password: false, passwordMessage: '' }));
  };

  const onChangeRole = (e) => {
    setData((prev) => ({ ...prev, role_id: e.target.value }));
  };

  const onChangeVerified = (e) => {
    setData((prev) => ({ ...prev, verified: !prev.verified }));
  };

  const onChangeActive = (e) => {
    setData((prev) => ({ ...prev, active: !prev.active }));
  };

  const handleClickCreate = () => {
    if (data.name === '') {
      setErrorForm((prev) => ({
        ...prev,
        name: true,
        nameMessage: 'Name cannot empty',
      }));
    }
    if (data.email === '') {
      setErrorForm((prev) => ({
        ...prev,
        email: true,
        EmailMessage: 'Email cannot empty',
      }));
    }
    if (data.password === '') {
      setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'Password cannot empty',
      }));
    }
    if (data.name === '' || data.email === '' || data.password === '') return;

    if (data.password.length < 8) {
      return setErrorForm((prev) => ({
        ...prev,
        password: true,
        passwordMessage: 'Minimum password is 8 character',
      }));
    }

    const dataObject = {
      name: data.name,
      email: data.email,
      password: data.password,
      role_id: data.role_id,
      verified: data.verified,
      active: data.active,
    };
    axiosApi
      .post('api/user', JSON.stringify(dataObject))
      .then((res) => {
        console.log(res);
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'success',
          message: `Success create user with email ${data.email}`,
        }));
        setOpenDialog(false);
      })
      .catch((err) => {
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          status: 'error',
          message: err.response.data.message,
        }));
        console.log(err.response.data);
      });

    refresh();
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          label="Name"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          value={data.name}
          onChange={onChangeName}
          error={errorForm.name}
          helperText={errorForm.nameMessage}
        />
        <TextField
          id="email"
          label="Email"
          type="email"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          value={data.email}
          onChange={onChangeEmail}
          error={errorForm.email}
          helperText={errorForm.EmailMessage}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          value={data.password}
          onChange={onChangePassword}
          error={errorForm.password}
          helperText={errorForm.passwordMessage}
        />
        <Box mt={2}>
          <FormControl>
            <FormLabel id="role">Role</FormLabel>
            <RadioGroup
              row
              aria-labelledby="role"
              name="role"
              value={data.role_id}
              onChange={onChangeRole}
            >
              {allRole.map((each, index) => (
                <FormControlLabel
                  value={each.id}
                  control={<Radio />}
                  label={_.startCase(each.role_name)}
                  key={index}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mt={3}>
          <FormLabel component="legend">Verified</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Switch name="active" />}
              checked={data.verified}
              onChange={onChangeVerified}
              label={data.verified ? 'Yes' : 'No'}
            />
          </FormGroup>
        </Box>
        <Box mt={3}>
          <FormLabel component="legend">Active User</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Switch name="active" />}
              checked={data.active}
              onChange={onChangeActive}
              label={data.active ? 'Yes' : 'No'}
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Cancel</Button>
        <Button onClick={handleClickCreate} disabled={false}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDashboardAddUser;
