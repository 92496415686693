import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box component="img" src="../assets/not_found.svg" height={200} />
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              align="center"
              fontFamily="Montserrat"
              variant="h5"
              color="#333"
              sx={{
                lineHeight: '29px',
                fontWeight: '500',
                mb: 1,
              }}
              mt={2}
            >
              Nothing to see here
            </Typography>
            <Typography
              align="center"
              variant="body1"
              fontFamily="Montserrat"
              color="#4f4f4f"
              sx={{
                lineHeight: '29px',
                fontWeight: '400',
              }}
            >
              Go back where you belong
            </Typography>
            <Button
              variant="contained"
              sx={{
                width: 'fit-content',
                mt: 2,
                backgroundColor: '#FABC1D',
                '&:hover': {
                  backgroundColor: '#FABC1D',
                },
              }}
              onClick={() => navigate('/')}
            >
              Home
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default NotFound;
