import { BrowserRouter } from 'react-router-dom';
import MainRouting from './routing/MainRouting';
import { UserProvider } from './utils/UserContext';
import { TransactionProvider } from './utils/TransactionContext';

function App() {
  return (
    <UserProvider>
      <TransactionProvider>
        <BrowserRouter>
          <MainRouting />
        </BrowserRouter>
      </TransactionProvider>
    </UserProvider>
  );
}

export default App;
