import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ResetPasswordCreate from '../components/ResetPasswordCreate';
import ResetPasswordFailed from '../components/ResetPasswordFailed';
import ResetPasswordRequest from '../components/ResetPasswordRequest';
import axiosApi from '../utils/axiosApi';

function ResetPassword() {
  let [searchParams] = useSearchParams();
  const [isRequest, setIsRequest] = useState(null);
  const [falseToken, setFalseToken] = useState(null);
  const firstRender = useRef(false);

  const token = searchParams.get('token');
  const guid = searchParams.get('guid');

  useEffect(() => {
    document.title = 'Soup Project | Reset Password';
  }, []);

  useEffect(() => {
    if (firstRender.current) return;
    firstRender.current = true;

    if (guid && token) {
      const data = { guid, token };
      axiosApi
        .post('api/reset-password', JSON.stringify(data))
        .then((res) => {
          if (res.data.data === true) {
            setIsRequest(false);
            setFalseToken(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsRequest(false);
          setFalseToken(true);
        });
    } else {
      setIsRequest(true);
    }
  }, [guid, token]);

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      p={5}
    >
      <Box display="flex" flexDirection="column" width="100%" maxWidth="sm">
        {isRequest === true && <ResetPasswordRequest />}
        {isRequest === false && falseToken === false && (
          <ResetPasswordCreate token={token} guid={guid} />
        )}
        {isRequest === false && falseToken === true && <ResetPasswordFailed />}
      </Box>
    </Box>
  );
}

export default ResetPassword;
