import React, { useEffect } from 'react';
import { Box, Breadcrumbs, Container, Typography } from '@mui/material'
import DataTableInvoice from '../components/DataTableInvoice';
import { Link, useLocation } from 'react-router-dom';
// import data from '../data/dataTestingInvoice.json';

const columns = ['No', 'Course Name', 'Type', 'Schedule', 'Price']

function InvoiceDetail() {
  const location = useLocation()
  const course = location.state.course
  const noInvoice = location.state.noInvoice
  const date = location.state.date
  const totalPrice = location.state.totalPrice

  useEffect(() => {
    document.title = 'Soup Project | Invoice Detail'
  }, [])

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' mt={5} mx={1} flexGrow={1} >
      <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
        <Breadcrumbs aria-label="breadcrumbs" separator=">"
          size="md">
          {['Home', 'Invoice'].map((item) => (
            <Typography
              sx={{ textDecoration: 'none' }}
              key={item}
              component={Link}
              to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
              color='gray3'
              variant='body1'
              fontWeight={600}
              lineHeight={2}>{item}</Typography>
          ))}

          <Typography color='secondary'
            variant='body1' sx={{
              lineHeight: '20px',
              fontWeight: '600',
            }}>Invoice Detail</Typography>
        </Breadcrumbs>

        <DataTableInvoice
          title='Detail Invoice'
          columns={columns}
          data={course}
          noInvoice={noInvoice}
          date={date}
          totalPrice={totalPrice}
          invoiceDetail
        />
      </Container>
    </Box >
  )
}

export default InvoiceDetail;
