import React from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';

import { formatCurrency } from '../utils/';

import { Link } from 'react-router-dom';

function CardLandingPage({
  imageSource,
  productName,
  productPrice,
  slug,
  catergoryName,
  dataCourses,
}) {
  let urlName = `/course/${slug}`;

  return (
    <Grid item xs={12} sm={6} md={4} sx={{ p: 0 }}>
      <Card sx={{ height: '100%' }}>
        <CardActionArea
          component={Link}
          to={urlName}
          state={{ dataCourses: dataCourses }}
        >
          <CardMedia
            component="img"
            width="350px"
            image={imageSource}
            alt={productName}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              padding: { xs: 2, lg: 2 },
              // minHeight: { xs: '200px', lg: '100%' },
            }}
          >
            <Typography gutterBottom variant="body1" justifySelf={'start'}>
              {catergoryName}
            </Typography>
            <Typography gutterBottom variant="h6" fontWeight={600}>
              {productName}
            </Typography>
            <Typography
              variant="h6"
              mt={{ lg: 4, xs: 2 }}
              color="primary.main"
              fontWeight={600}
            >
              {formatCurrency(productPrice)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default CardLandingPage;
