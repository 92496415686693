import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

function CardInfoLandingPage({ infoHeader, infoBody }) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: { xs: '100%', md: '240px' },
        height: { xs: 'fit-content', md: '210px' },
        borderRadius: 8,
        flexGrow: { xs: 0, lg: 1 },
      }}
    >
      <CardContent>
        <Typography
          variant="h3"
          color="primary.main"
          align="center"
          fontWeight={600}
        >
          {infoHeader}
        </Typography>
        <Typography align="center" variant="subtitle1" mt={2}>
          {infoBody}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CardInfoLandingPage;
