import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TransactionContext from '../../utils/TransactionContext';
import UserContext from '../../utils/UserContext';

function Header() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);

  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { currentCart } = useContext(TransactionContext);

  const navigateHeader = (link = '') => {
    setOpenNavbar(false);
    navigate(`/${link}`);
  };

  const logoutUser = () => {
    setOpenNavbar(false);
    localStorage.removeItem('auth');
    localStorage.removeItem('my-class');
    localStorage.removeItem('cart');
    localStorage.removeItem('buy-now');
    localStorage.removeItem('invoice');
    setCurrentUser(false);
    navigate('/');
  };

  useEffect(() => {
    setCartTotal(currentCart.length <= 100 ? currentCart.length : '99+');
  }, [currentCart]);

  const ListItemMobile = ({ text, path, buttonFunction }) => {
    return (
      <ListItem>
        <ListItemButton
          onClick={buttonFunction ? buttonFunction : () => navigateHeader(path)}
        >
          <ListItemText primary={text} sx={{ textAlign: 'center' }} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        color="white"
        elevation={0}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ my: 1 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="img"
                src="/assets/logo.png"
                onClick={() => navigateHeader()}
                sx={{ cursor: 'pointer' }}
              />
            </Box>

            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
              }}
            >
              {currentUser && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => navigateHeader('checkout')}
                >
                  <Badge badgeContent={cartTotal} color="primary">
                    <ShoppingCartIcon
                      color="secondary"
                      sx={{ fontSize: '18px' }}
                    />
                  </Badge>
                </IconButton>
              )}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setOpenNavbar(!openNavbar)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            {currentUser === false && (
              <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' } }}>
                <Button
                  variant="contained"
                  color="white"
                  size="small"
                  disableElevation
                  sx={{ mx: 2, border: 1, width: '150px' }}
                  onClick={() => navigateHeader('login')}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  sx={{ mx: 2, width: '150px' }}
                  onClick={() => navigateHeader('register')}
                >
                  Register
                </Button>
              </Box>
            )}
            {currentUser && (
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <IconButton onClick={() => navigateHeader('checkout')}>
                  <Badge badgeContent={cartTotal} color="primary">
                    <ShoppingCartIcon
                      color="secondary"
                      sx={{ fontSize: '18px' }}
                    />
                  </Badge>
                </IconButton>
                <Button onClick={() => navigateHeader('myclass')}>
                  <Typography fontWeight={500} px={2}>
                    My Class
                  </Typography>
                </Button>
                <Button onClick={() => navigateHeader('invoice')}>
                  <Typography fontWeight={500} px={2}>
                    Invoice
                  </Typography>
                </Button>
                <Divider orientation="vertical" flexItem variant="middle" />
                <IconButton onClick={() => navigateHeader('dashboard')}>
                  <PersonIcon color="primary" sx={{ fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={logoutUser}>
                  <LogoutIcon color="secondary" sx={{ fontSize: '18px' }} />
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="top"
        open={openNavbar}
        onClose={() => setOpenNavbar(false)}
        elevation={0}
      >
        <Toolbar disableGutters sx={{ my: 1 }} />
        <Box sx={{ overflow: 'auto' }}>
          {currentUser === false && (
            <List>
              <Divider />
              <ListItemMobile
                text="Login"
                buttonFunction={() => {
                  setOpenNavbar(false);
                  navigate('login');
                }}
              />
              <ListItemMobile text="Register" path="register" />
            </List>
          )}
          {currentUser && (
            <List>
              <Divider />
              <ListItemMobile text="My Class" path="myclass" />
              <ListItemMobile text="Invoice" path="invoice" />
              <Divider />
              <ListItemMobile text="Dashboard" path="dashboard" />
              <ListItemMobile text="Logout" buttonFunction={logoutUser} />
            </List>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default Header;
