import React, { useState, useEffect, createContext } from 'react';
import axiosApi from './axiosApi';

const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const [currentMyClass, setCurrentMyClass] = useState([]);
  const [currentCart, setCurrentCart] = useState([]);
  const [currentBuyNow, setCurrentBuyNow] = useState(undefined);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const [updateOnCart, setUpdateOnCart] = useState(false);
  const [updateOnClass, setUpdateOnClass] = useState(false);
  const [updateOnInvoice, setUpdateOnInvoice] = useState(false);

  useEffect(() => {
    const getLocalStorage = () => {
      let lsMyClass = localStorage.getItem('my-class');
      let lsCurrentCart = localStorage.getItem('cart');
      let lsCurrentBuyNow = localStorage.getItem('buynow');
      let lsCurrentInvoice = localStorage.getItem('invoice');

      if (lsMyClass) setCurrentMyClass(JSON.parse(lsMyClass));
      if (lsCurrentCart) setCurrentCart(JSON.parse(lsCurrentCart));
      if (lsCurrentBuyNow) setCurrentBuyNow(JSON.parse(lsCurrentBuyNow));
      if (lsCurrentInvoice) setCurrentInvoice(JSON.parse(lsCurrentInvoice));
    };

    getLocalStorage();
  }, []);

  useEffect(() => {
    if (!updateOnCart) return;
    const getAllCart = async () => {
      try {
        const res = await axiosApi.get(`/api/cartproduct/user`);
        localStorage.removeItem('cart');
        localStorage.setItem('cart', JSON.stringify(res.data));
        setCurrentCart(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setUpdateOnCart(false);
      }
    };
    getAllCart();
  }, [updateOnCart]);

  useEffect(() => {
    if (!updateOnClass) return;
    const getAllClass = async () => {
      try {
        const res = await axiosApi.get('api/myclass/user');
        localStorage.removeItem('my-class');
        localStorage.setItem('my-class', JSON.stringify(res.data));
        setCurrentMyClass(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setUpdateOnClass(false);
      }
    };
    getAllClass();
  }, [updateOnClass]);

  useEffect(() => {
    if (!updateOnInvoice) return;
    const getAllInvoice = async () => {
      try {
        const res = await axiosApi.get('api/order/user');
        localStorage.removeItem('invoice');
        localStorage.setItem('invoice', JSON.stringify(res.data));
        setCurrentInvoice(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setUpdateOnInvoice(false);
      }
    };
    getAllInvoice();
  }, [updateOnInvoice]);

  return (
    <TransactionContext.Provider
      value={{
        currentMyClass,
        setCurrentMyClass,
        currentCart,
        setCurrentCart,
        currentBuyNow,
        setCurrentBuyNow,
        setUpdateOnCart,
        updateOnCart,
        currentInvoice,
        setCurrentInvoice,
        updateOnClass,
        setUpdateOnClass,
        updateOnInvoice,
        setUpdateOnInvoice,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionContext;
