import {
  Box,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import UserContext from '../../utils/UserContext';
import Footer from './Footer';
import axiosApi from '../../utils/axiosApi';
import TransactionContext from '../../utils/TransactionContext';
import Header from './Header';

function MainLayout(props) {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { noFooter } = props;
  const navigate = useNavigate();

  const { currentCart, setUpdateOnCart } = useContext(TransactionContext);

  const dateNow = new Date();

  const toDeletePassSchedule = currentCart
    .map((data) => {
      return {
        guid: data.guid,
        schedule: Date.parse(data.schedule),
      };
    })
    .filter((data) => data.schedule < dateNow.setHours(0, 0, 0, 0));

  useEffect(() => {
    const deletePastTimes = async () => {
      try {
        for (let index = 0; index < toDeletePassSchedule.length; index++) {
          const res = await axiosApi.delete(
            `/api/cartproduct/${toDeletePassSchedule[index].guid}`
          );
          if (res) setUpdateOnCart(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    deletePastTimes();
  }, [
    setUpdateOnCart,
    toDeletePassSchedule.length,
    toDeletePassSchedule.guid,
    toDeletePassSchedule,
  ]);

  let theme = createTheme({
    palette: {
      primary: {
        main: '#FABC1D',
      },
      secondary: {
        main: '#5B4947',
      },
      white: {
        main: '#FFF',
      },
      gray1: '#333',
      gray2: '#4F4F4F',
      gray3: '#828282',
    },
    typography: {
      fontFamily: ['Montserrat', 'Poppins', 'Inter'].join(','),
      allVariants: {
        color: '#4F4F4F',
      },
    },
  });

  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem('auth');
      localStorage.removeItem('my-class');
      localStorage.removeItem('cart');
      localStorage.removeItem('buy-now');
      localStorage.removeItem('invoice');
      setCurrentUser(false);
      navigate('/');
    };

    if (currentUser) {
      const dataJWT = jwtDecode(currentUser);
      if (new Date(dataJWT.exp * 1000) <= new Date()) {
        logoutUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  theme = responsiveFontSizes(theme);

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <ThemeProvider theme={theme}>
        <Header />
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Outlet />
        </Box>
        {!noFooter && <Footer />}
      </ThemeProvider>
    </Box>
  );
}

export default MainLayout;
