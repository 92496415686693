import {
  Alert,
  Button,
  Paper,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DialogDashboardPaymentMethodEdit from '../../components/DialogDashboardPaymentMethodEdit';
import _ from 'lodash';

import axiosApi from '../../utils/axiosApi';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DialogDashboardPaymentMethodAdd from '../../components/DialogDashboardPaymentMethodAdd';

function DashboardPaymentMethod() {
  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [editedPaymentMethod, setEditedPaymentMethod] = useState({
    id: 0,
    name: '',
    logo: '',
  });
  const [newPaymentDialog, setNewPaymentDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    status: 'success',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (snackbar.open === true) {
      axiosApi
        .get('api/paymentmethod')
        .then((res) => {
          setPaymentMethodData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [snackbar.open]);

  useEffect(() => {
    document.title = 'Soup Project | Dashboard Payment Method';
    axiosApi
      .get('api/paymentmethod')
      .then((res) => {
        setPaymentMethodData(res.data);
        _.delay(() => setIsLoading(false), 1000);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOpenEditDialog = (id) => {
    setEditDialog(true);
    const selectedData = _.find(paymentMethodData, { id });
    setEditedPaymentMethod(selectedData);
  };

  const handleOpenCloseSnackbar = (type) => {
    type === 'open' && setSnackbar((prev) => ({ ...prev, open: true }));
    type === 'close' && setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Paper>
        <Box px={3} py={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" ml={1}>
              All Payment Method
            </Typography>
            {!isLoading && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                sx={{ mx: 2, width: '150px' }}
                onClick={() => setNewPaymentDialog(true)}
              >
                Add
              </Button>
            )}
            {isLoading && (
              <Skeleton variant="rectangular" height={35} width={150} />
            )}
          </Box>
          <Box component={TableContainer} mt={2}>
            {isLoading && (
              <Box>
                <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
                <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
                <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
                <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
                <Skeleton variant="rectangular" height={50} sx={{ mt: 2 }} />
              </Box>
            )}
            {!isLoading && (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell align="center">Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentMethodData.map((data, index) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      key={index}
                    >
                      <TableCell component="th" scope="row">
                        {data.id}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box component={'img'} src={data.logo} mr={2} />
                          {data.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {data.active ? <CheckIcon /> : <CloseIcon />}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          onClick={() => {
                            handleOpenEditDialog(data.id);
                          }}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Box>
      </Paper>
      <DialogDashboardPaymentMethodEdit
        openDialog={editDialog}
        setOpenDialog={setEditDialog}
        data={editedPaymentMethod}
        setSnackbar={setSnackbar}
      />
      <DialogDashboardPaymentMethodAdd
        openDialog={newPaymentDialog}
        setOpenDialog={setNewPaymentDialog}
        setSnackbar={setSnackbar}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => handleOpenCloseSnackbar('close')}
          severity={snackbar.status}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DashboardPaymentMethod;
